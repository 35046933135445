import {
  AfterViewChecked,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  QueryList,
  Renderer2,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { Order } from 'src/app/models-classes/order/order.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CatalogService } from 'src/app/services/catalogs/catalog.service';
import { FavoriteService } from 'src/app/services/favorites/favorite.service';
import { SettingsService } from 'src/app/services/settings.service';
import { getUniqueId } from 'src/app/shared/constants/global-variable.model';
declare var $: any;

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.css'],
  encapsulation: ViewEncapsulation.None, //change style for child in parent
})
export class CompanyComponent implements OnInit, AfterViewChecked {
  days = [
    { id: 1, name: 'Sunday' },
    { id: 2, name: 'Monday' },
    { id: 3, name: 'Tuesday' },
    { id: 4, name: 'Wednesday' },
    { id: 5, name: 'Thursday' },
    { id: 6, name: 'Friday' },
    { id: 7, name: 'Saturday' },
  ];
  order: any;
  attributeDetails: any[] = [];
  product: any;
  productName = '';
  description = '';
  price = 0;
  fullPrice = 0;
  isNote = false;
  fav = false;
  extraDetails = '';
  detailStates: { [key: number]: boolean } = {};
  id = 0;
  productId = 0;
  company: any;
  rate = 4;
  current_lang = localStorage.getItem('current_lang') || 'en';

  companyProducts: any[] = [];
  companyReviews: any[] = [];
  constructor(
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private modalConfig: NgbModalConfig,
    private catalogService: CatalogService,
    private elementRef: ElementRef,
    public settingService: SettingsService,
    public authService: AuthService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private favoriteService: FavoriteService,
    private renderer: Renderer2
  ) {
    this.modalConfig.centered = true;
    this.modalConfig.container = 'body';
  }
  enforceMaxSelectedOptions() {
    $('[id^="subCheckboxtree"]').each(function () {
      const subAttributeId = $(this)
        .attr('name')
        .replace('subCheckboxtree', '');
      const maxSelectedOptions = $(this).data('max-selected-options');

      if (maxSelectedOptions) {
        const selectedCount = $(
          `[name="subCheckboxtree${subAttributeId}"]:checked`
        ).length;
        if (selectedCount >= maxSelectedOptions) {
          $(`[name="subCheckboxtree${subAttributeId}"]`)
            .not(':checked')
            .prop('disabled', true);
        } else {
          $(`[name="subCheckboxtree${subAttributeId}"]`).prop(
            'disabled',
            false
          );
        }
      }
    });
  }
  ngAfterViewChecked() {
    this.enforceMaxSelectedOptions();
  }
  day_count = 0;
  isWorkDay(dayId: number): boolean {
    this.day_count = this.company?.workDays.split(',').length;
    return this.company?.workDays.split(',').includes(dayId.toString());
  }
  // @ViewChildren('radioButtons') radioButtons: QueryList<ElementRef<HTMLInputElement>>;
  // addDynamicStyles(): void {
  //   const style = this.renderer.createElement('style');
  //   style.innerHTML = `
  //     .ms-image-slider .carousel-indicators {
  //       margin: 2% 0 0;
  //       bottom: 0;
  //       background: rgba(0, 0, 0, 0.4);
  //       padding: 1rem 0;
  //       display: flex;
  //       justify-content: center;
  //     }
  //     .ms-image-slider .carousel-indicators li {
  //       width: 80px;
  //       height: 50px;
  //       border-radius: 4px;
  //       overflow: hidden;
  //       margin: 0 5px;
  //       cursor: pointer;
  //       list-style: none;
  //       position: relative;
  //     }
  //     .ms-image-slider .carousel-indicators li:before {
  //       content: '';
  //       position: absolute;
  //       top: 0;
  //       left: 0;
  //       width: 100%;
  //       height: 100%;
  //       background-position: center;
  //       background-size: cover;
  //       background-repeat: no-repeat;
  //       border: 2px solid transparent;
  //     }
  //     .ms-image-slider .carousel-indicators li.active:before {
  //       border-color: #fff;
  //     }
  //     .ms-image-slider .carousel-indicators li:not(.active):before {
  //       opacity: 0.7;
  //     }
  //   `;

  //   this.companyProducts.forEach((item) => {
  //     item.products.forEach((product) => {
  //       product.productImages.forEach((image, imageIndex) => {
  //         style.innerHTML += `
  //           #shoppingBox${item.id} .product-${product.id} .ms-image-slider .carousel-indicators li:nth-child(${imageIndex + 1}):before {
  //             background-image: url('${image.image}');
  //           }
  //         `;
  //       });
  //     });
  //   });

  //   this.renderer.appendChild(document.head, style);
  // }
  // updateOptionQuantity(detailId: number, optionId: number, change: number) {
  //   if (!this.optionQuantities[detailId]) {
  //     this.optionQuantities[detailId] = {};
  //   }
  //   if (this.optionQuantities[detailId][optionId] === undefined) {
  //     this.optionQuantities[detailId][optionId] = 0;
  //   }

  //   const newQuantity = this.optionQuantities[detailId][optionId] + change;
  //   if (newQuantity >= 0 && this.getTotalQuantity(detailId) + change <= this.getMaxQuantity(detailId)) {
  //     this.optionQuantities[detailId][optionId] = newQuantity;
  //   }
  // }

  // getTotalQuantity(detailId: number): number {
  //   return Object.values(this.optionQuantities[detailId] || {}).reduce((total, qty) => total + qty, 0);
  // }

  // getMaxQuantity(detailId: number): number {
  //   const detail = this.attributeDetails.find(d => d.id === detailId);
  //   return detail ? detail.maxSelectedOptions : 0;
  // }
  // addonProducts = [
  //   { id: 1, name: 'Extra Cheese', image: 'https://rahaldelivery.blob.core.windows.net/uploads/Product/AllAttributes/b8159168-4578-41c5-b813-399d38e9f848.jpeg', price: 1.5 },
  //   { id: 2, name: 'Bacon', image: 'https://rahaldelivery.blob.core.windows.net/uploads/Product/AllAttributes/b8159168-4578-41c5-b813-399d38e9f848.jpeg', price: 2.0 },
  //   // Add more addons as needed
  // ];
  addonQuantities = {};
  updateAddonQuantity(addonId, change) {
    if (!this.addonQuantities[addonId]) {
      this.addonQuantities[addonId] = 0;
    }
    this.addonQuantities[addonId] = Math.max(
      0,
      this.addonQuantities[addonId] + change
    );
    this.calculateFullTotal();
  }
  item: any;
  onOptionChange(detail, option) {
    // Clear previous sub-options
    if (this.selectedOptions[detail.id]) {
      const prevOptionId = this.selectedOptions[detail.id];
      const prevOption = detail.attributeOptions.find(
        (opt) => opt.id === prevOptionId
      );
      if (prevOption && prevOption.subAttributes) {
        prevOption.subAttributes.forEach((subAttribute) => {
          subAttribute.attributeOptions.forEach((subOption) => {
            const subOptionId = subOption.id;
            // Uncheck previous sub-options
            $(`#subRadiotree${subOptionId}`).prop('checked', false);
            $(`#subCheckboxtree${subOptionId}`).prop('checked', false);
          });
        });
      }
    }

    // Set the newly selected option
    this.selectedOptions[detail.id] = option.id;

    // Reset selectedSubOptions
    this.selectedSubOptions = {};

    // Recalculate fullPrice
    this.calculateFullTotal();
  }

  updateOptionQuantity(detailId: number, optionId: number, change: number) {
    const detail = this.attributeDetails.find((d) => d.id === detailId);
    console.log(detail);
    if (!detail) return;
    if (!this.optionQuantities[detailId]) {
      this.optionQuantities[detailId] = {};
    }
    if (this.optionQuantities[detailId][optionId] === undefined) {
      this.optionQuantities[detailId][optionId] = 0;
    }

    const currentQuantity = this.optionQuantities[detailId][optionId];
    const newQuantity = currentQuantity + change;
    const maxQuantity = this.getMaxQuantity(detailId);

    if (
      newQuantity >= 0 &&
      this.getTotalQuantity(detailId) + change <= maxQuantity
    ) {
      this.optionQuantities[detailId][optionId] = newQuantity;

      // Automatically distribute remaining quantity
      const totalQuantity = this.getTotalQuantity(detailId);
      const remainingQuantity = maxQuantity - totalQuantity;

      if (remainingQuantity > 0) {
        const otherOptions = detail.attributeOptions.filter(
          (option) => option.id !== optionId
        );
        otherOptions.forEach((option) => {
          const otherOptionId = option.id;
          const remainingPerOption = Math.floor(
            remainingQuantity / otherOptions.length
          );
          this.optionQuantities[detailId][otherOptionId] += remainingPerOption;
        });
      }
      // Calculate fullPrice
      // this.fullPrice = this.price; // Start with the base price
    }
    this.calculateFullTotal();
  }

  // getTotalQuantity(detailId: number): number {
  //   return Object.values(this.optionQuantities[detailId] || {}).reduce(
  //     (total, qty) => total + qty,
  //     0
  //   );
  // }

  // getMaxQuantity(detailId: number): number {
  //   const detail = this.attributeDetails.find((d) => d.id === detailId);
  //   return detail ? detail.maxSelectedOptions : 0;
  // }

  // isOptionDisabled(subAttribute: any, subOption: any): boolean {
  //   return this.selectedSubOptions[subAttribute.id]?.length >= subAttribute.maxSelectedOptions && !this.selectedSubOptions[subAttribute.id].includes(subOption.id);
  // }

  getTotalQuantity(detailId: number): number {
    return Object.values(this.optionQuantities[detailId] || {}).reduce(
      (acc, quantity) => acc + quantity,
      0
    );
  }
  getTotalSelectedOptions(subAttributeId: number): number {
    return this.selectedSubOptions[subAttributeId]?.size || 0;
  }
  getMaxQuantity(detailId: number): number {
    const detail = this.attributeDetails.find((d) => d.id === detailId);
    console.log(detail);
    return detail ? detail.maxSelectedOptions : 0;
  }

  selectedOptions: { [key: number]: number } = {};
  optionQuantities: { [key: number]: { [key: number]: number } } = {};
  selectedSubOptions: { [key: number]: Set<number> } = {};
  isOptionDisabled(subAttribute: any, subOption: any): boolean {
    return (
      this.selectedSubOptions[subAttribute.id]?.size >=
        subAttribute.maxSelectedOptions &&
      !this.selectedSubOptions[subAttribute.id]?.has(subOption.id)
    );
  }

  addDynamicStyles(): void {
    const style = this.renderer.createElement('style');
    style.innerHTML = `
      .ms-image-slider .carousel-indicators {
        margin: 2% 0 0;
        bottom: 0;
        padding: 1rem 0;
        display: flex;
        justify-content: center;
      }
      .ms-image-slider .carousel-indicators li {
        width: 80px;
        height: 50px;
        border-radius: 4px;
        overflow: hidden;
        margin: 0 5px;
        cursor: pointer;
        list-style: none;
        position: relative;
      }
      .ms-image-slider .carousel-indicators li:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        border: 2px solid transparent;
      }
      .ms-image-slider .carousel-indicators li.active:before {
        border-color: #fff;
      }
      .ms-image-slider .carousel-indicators li:not(.active):before {
        opacity: 0.7;
      }
    `;

    this.companyProducts.forEach((item) => {
      item.products.forEach((product) => {
        product.productImages.forEach((image, imageIndex) => {
          style.innerHTML += `
            #shoppingBox${item.id} .product-${
            product.id
          } .ms-image-slider .carousel-indicators li:nth-child(${
            imageIndex + 1
          }):before {
              background-image: url('${image.image}');
            }
          `;
        });
      });
    });

    this.renderer.appendChild(document.head, style);
  }

  active = 2;
  cost = 0;
  isSticky: boolean = false;
  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(event) {
    // let element = document.getElementById('shoppingBox78');
    //   console.log(element.offsetTop);
  }
  getConcatenatedNames(): string {
    return this.company?.categories.map((category) => category.name).join(', ');
  }
  changeFullPrice(event) {
    this.calculateFullTotal();
  }
  ngAfterViewInit() {
    const scroller = document.querySelector('#content');
    const topnav = document.querySelector<HTMLElement>('#top-nav');
    scroller.addEventListener('scroll', (event) => {
      if (scroller.scrollTop >= 454) {
        topnav.style.cssText =
          'position: fixed;top: 50px; left: 0; right: 0; z-index: 3000; background-color: #fff; padding: 10px 20px; ';
      } else {
        topnav.style.cssText = '';
      }
      // console.log(element.offsetTop)
      // if(element.scrollTop > 80){
      //   console.log("rrr")
      // }
    });
    // this.radioButtons.forEach((radioButton, index) => {
    //   if (radioButton.nativeElement.checked) {
    //     // If the radio button is checked initially, manually trigger the change event
    //     this.changeFullPrice(null);
    //   }
    // });
  }
  orders: any[] = [];
  resetRadioButtons() {
    $('input[type="radio"]').each(function () {
      var controlId = $(this).attr('id');
      if (controlId && controlId.includes('radio')) {
        $(this).prop('checked', false);
      }
    });
  }
  resetCheckboxButtons() {
    $('input[type="checkbox"]').each(function () {
      var controlId = $(this).attr('id');
      if (controlId && controlId.includes('checkbox')) {
        $(this).prop('checked', false);
      }
    });
  }
  addOrder() {
    const conditionsMetResult = this.areConditionsMet();
    if (!conditionsMetResult.isValid) {
      // Display toast with attribute name
      this.toastr.error(
        `${this.translate.instant('Please select required options for')} ${
          conditionsMetResult.attributeName
        }`,
        'Error'
      );
      return; // Prevent further execution
    }
    this.order = {
      companyId: this.id,
      branchId: this.branchId,
      uniqueId: getUniqueId(10),
      customerAddressId: 0,
      discount: 0,
      deliverFees: this.product.deliveryFees,
      notes: '',
      paymentTypeId: 0,
      orderDate: new Date(),
      orderNumber: 1,
      companyName: this.company.name,
      tax: 1,
      total: this.fullPrice,
      oldPrice: this.fullPrice,
      id: 0,
      productName: this.product.name,
      productId: this.productId,
      extraDetails: this.extraDetails,
      quantity: +$('.input-group-field').val(),
    };
    console.log(this.order);
    this.settingService.addItemToLocalstorage(this.order, 'my_orders');
    this.extraDetails = '';
    if (this.attributeDetails.length) {
      this.resetCheckboxButtons();
      this.resetRadioButtons();
      $('#addItemToBasket').modal('hide');
    }
    // if (
    //   this.settingService.getData('my_orders') &&
    //   this.settingService.getData('my_orders')[0].companyId !=
    //     this.order.companyId
    // ) {
    // }
    this.fillOrders();
    console.log(this.settingService.getData('my_orders'));
  }
  branchId = 0;
  addItemToBasket(
    id,
    branchId,
    companyProductId,
    name,
    description,
    price,
    isAvailable
  ) {
    if (!isAvailable) {
      this.toastr.warning(this.translate.instant('ProductNotAvailable'));
      return;
    }

    this.branchId = branchId;
    this.productId = id;
    this.productName = name;
    this.description = description;
    this.price = price;
    this.fullPrice = price;
    this.product = this.companyProducts
      .find((c) => c.id == companyProductId)
      .products.find((p) => p.id == id);
    this.attributeDetails = this.companyProducts
      .find((x) => x.id === companyProductId)
      .products.find((x) => x.id == id).attributesDetails;
    const addOnIds = this.product.addOns.split(',').map((id) => id.trim());
    this.addonProducts = this.addonProducts.filter((product) =>
      addOnIds.includes(product.id.toString())
    );
    console.log(this.addonProducts);
    console.log(this.attributeDetails);
    this.attributeDetails.forEach((detail) => {
      if (detail.type === 1 || detail.type === 2) {
        this.optionQuantities[detail.id] = {};
        if (detail.type === 2) {
          this.selectedOptions[detail.id] = null;
        }
        detail.attributeOptions.forEach((option) => {
          this.optionQuantities[detail.id][option.id] = 0;
        });
      }
    });

    if (this.attributeDetails.length) {
      $('#addItemToBasket').modal('show');
      console.log(this.attributeDetails);
      for (let det of this.attributeDetails) {
        if (det.isRequired) {
          if (det.attributeOptions && det.attributeOptions.length == 1)
            this.fullPrice += det.attributeOptions[0].price;
        }
      }
    } else {
      this.addOrder();
    }
  }
  // areConditionsMet(): { isValid: boolean; attributeName?: string } {
  //   for (let detail of this.attributeDetails) {
  //     // If minSelectedOptions is defined
  //     if (
  //       detail.minSelectedOptions !== undefined &&
  //       detail.minSelectedOptions !== null
  //     ) {
  //       let selectedCount = 0;
  //       if (detail.type === 0) {
  //         // Radio buttons
  //         const selectedRadio = document.querySelector(
  //           `input[name="radio${detail.id}"]:checked`
  //         ) as HTMLInputElement;
  //         if (selectedRadio) {
  //           selectedCount++;
  //         }
  //       } else {
  //         // Checkboxes
  //         for (let option of detail.attributeOptions) {
  //           const checkbox = document.querySelector(
  //             `input[name="checkbox${option.id}"]:checked`
  //           ) as HTMLInputElement;
  //           if (checkbox) {
  //             selectedCount++;
  //           }
  //         }
  //       }
  //       if (selectedCount < detail.minSelectedOptions) {
  //         return { isValid: false, attributeName: detail.name }; // If minSelectedOptions are not met, return false along with the detail name
  //       }
  //     }

  //     // If maxSelectedOptions is defined
  //     if (
  //       detail.maxSelectedOptions !== undefined &&
  //       detail.maxSelectedOptions !== null
  //     ) {
  //       let selectedCount = 0;
  //       if (detail.type === 0) {
  //         // Radio buttons
  //         const selectedRadio = document.querySelector(
  //           `input[name="radio${detail.id}"]:checked`
  //         ) as HTMLInputElement;
  //         if (selectedRadio) {
  //           selectedCount++;
  //         }
  //       } else {
  //         // Checkboxes
  //         for (let option of detail.attributeOptions) {
  //           const checkbox = document.querySelector(
  //             `input[name="checkbox${option.id}"]:checked`
  //           ) as HTMLInputElement;
  //           if (checkbox) {
  //             selectedCount++;
  //           }
  //         }
  //       }
  //       if (selectedCount > detail.maxSelectedOptions) {
  //         return { isValid: false, attributeName: detail.name }; // If maxSelectedOptions are exceeded, return false along with the detail name
  //       }
  //     }

  //     // If attribute is required but not selected
  //     if (detail.isRequired && detail.type === 0) {
  //       // For radio buttons
  //       const selectedRadio = document.querySelector(
  //         `input[name="radio${detail.id}"]:checked`
  //       ) as HTMLInputElement;
  //       if (!selectedRadio) {
  //         return { isValid: false, attributeName: detail.name }; // If required but not selected, return false along with the detail name
  //       }
  //     } else if (detail.isRequired && detail.type === 1) {
  //       // For checkboxes
  //       let atLeastOneSelected = false;
  //       for (let option of detail.attributeOptions) {
  //         const checkbox = document.querySelector(
  //           `input[name="checkbox${option.id}"]:checked`
  //         ) as HTMLInputElement;
  //         if (checkbox) {
  //           atLeastOneSelected = true;
  //           break;
  //         }
  //       }
  //       if (!atLeastOneSelected) {
  //         return { isValid: false, attributeName: detail.name }; // If required but not selected, return false along with the detail name
  //       }
  //     }
  //   }
  //   return { isValid: true }; // If all conditions are met, return true
  // }
  // areConditionsMet(): { isValid: boolean; attributeName?: string } {
  //   for (let detail of this.attributeDetails) {
  //     let selectedCount = 0;

  //     // Check minSelectedOptions for radio buttons or checkboxes
  //     if (detail.minSelectedOptions !== undefined && detail.minSelectedOptions !== null) {
  //       if (detail.type === 0) {
  //         // Radio buttons
  //         const selectedRadio = document.querySelector(`input[name="radiofirst${detail.id}"]:checked`) as HTMLInputElement;
  //         if (selectedRadio) {
  //           selectedCount++;
  //         }
  //       } else if (detail.type === 1) {
  //         // Quantity-based options
  //         for (let option of detail.attributeOptions) {
  //           const optionQuantity = this.optionQuantities[detail.id]?.[option.id] || 0;
  //           selectedCount += optionQuantity;
  //         }
  //       } else {
  //         // Checkboxes
  //         for (let option of detail.attributeOptions) {
  //           const checkbox = document.querySelector(`input[name="checkbox${option.id}"]:checked`) as HTMLInputElement;
  //           if (checkbox) {
  //             selectedCount++;
  //           }
  //         }
  //       }
  //       if (selectedCount < detail.minSelectedOptions) {
  //         return { isValid: false, attributeName: detail.name }; // If minSelectedOptions are not met, return false along with the detail name
  //       }
  //     }

  //     // Check maxSelectedOptions for radio buttons or checkboxes
  //     selectedCount = 0;
  //     if (detail.maxSelectedOptions !== undefined && detail.maxSelectedOptions !== null) {
  //       if (detail.type === 0) {
  //         // Radio buttons
  //         const selectedRadio = document.querySelector(`input[name="radiofirst${detail.id}"]:checked`) as HTMLInputElement;
  //         if (selectedRadio) {
  //           selectedCount++;
  //         }
  //       } else if (detail.type === 1) {
  //         // Quantity-based options
  //         for (let option of detail.attributeOptions) {
  //           const optionQuantity = this.optionQuantities[detail.id]?.[option.id] || 0;
  //           selectedCount += optionQuantity;
  //         }
  //       } else {
  //         // Checkboxes
  //         for (let option of detail.attributeOptions) {
  //           const checkbox = document.querySelector(`input[name="checkbox${option.id}"]:checked`) as HTMLInputElement;
  //           if (checkbox) {
  //             selectedCount++;
  //           }
  //         }
  //       }
  //       if (selectedCount > detail.maxSelectedOptions) {
  //         return { isValid: false, attributeName: detail.name }; // If maxSelectedOptions are exceeded, return false along with the detail name
  //       }
  //     }

  //     // Check required attributes for radio buttons or checkboxes
  //     if (detail.isRequired) {
  //       if (detail.type === 0) {
  //         // Radio buttons
  //         const selectedRadio = document.querySelector(`input[name="radiofirst${detail.id}"]:checked`) as HTMLInputElement;
  //         if (!selectedRadio) {
  //           return { isValid: false, attributeName: detail.name }; // If required but not selected, return false along with the detail name
  //         }
  //       } else if (detail.type === 1) {
  //         // Quantity-based options
  //         let atLeastOneSelected = false;
  //         for (let option of detail.attributeOptions) {
  //           const optionQuantity = this.optionQuantities[detail.id]?.[option.id] || 0;
  //           if (optionQuantity > 0) {
  //             atLeastOneSelected = true;
  //             break;
  //           }
  //         }
  //         if (!atLeastOneSelected) {
  //           return { isValid: false, attributeName: detail.name }; // If required but not selected, return false along with the detail name
  //         }
  //       } else {
  //         // Checkboxes
  //         let atLeastOneSelected = false;
  //         for (let option of detail.attributeOptions) {
  //           const checkbox = document.querySelector(`input[name="checkbox${option.id}"]:checked`) as HTMLInputElement;
  //           if (checkbox) {
  //             atLeastOneSelected = true;
  //             break;
  //           }
  //         }
  //         if (!atLeastOneSelected) {
  //           return { isValid: false, attributeName: detail.name }; // If required but not selected, return false along with the detail name
  //         }
  //       }
  //     }
  //   }

  //   return { isValid: true }; // If all conditions are met, return true
  // }
  // areConditionsMet(): { isValid: boolean; attributeName?: string } {
  //   for (let detail of this.attributeDetails) {
  //     let selectedCount = 0;

  //     // Check minSelectedOptions for radio buttons or checkboxes
  //     if (
  //       detail.minSelectedOptions !== undefined &&
  //       detail.minSelectedOptions !== null
  //     ) {
  //       if (detail.type === 0) {
  //         // Radio buttons
  //         const selectedRadio = document.querySelector(
  //           `input[name="radiofirst${detail.id}"]:checked`
  //         ) as HTMLInputElement;
  //         if (selectedRadio) {
  //           selectedCount++;
  //         }
  //       } else if (detail.type === 1) {
  //         // Quantity-based options
  //         for (let option of detail.attributeOptions) {
  //           const optionQuantity =
  //             this.optionQuantities[detail.id]?.[option.id] || 0;
  //           selectedCount += optionQuantity;
  //         }
  //       } else if (detail.type === 2) {
  //         // Radio buttons with sub-attributes
  //         const selectedRadio = document.querySelector(
  //           `input[name="radiotree${detail.id}"]:checked`
  //         ) as HTMLInputElement;
  //         if (selectedRadio) {
  //           selectedCount++;
  //           const selectedOption = detail.attributeOptions.find(
  //             (option) => option.id == selectedRadio.value
  //           );
  //           if (selectedOption) {
  //             for (let subAttribute of selectedOption.subAttributes) {
  //               let subSelectedCount = 0;
  //               for (let subOption of subAttribute.attributeOptions) {
  //                 const selectedSubOption =
  //                   (document.querySelector(
  //                     `input[name="subRadiotree${subOption.id}"]:checked`
  //                   ) as HTMLInputElement) ||
  //                   (document.querySelector(
  //                     `input[name="subCheckboxtree${subOption.id}"]:checked`
  //                   ) as HTMLInputElement);
  //                 if (selectedSubOption) {
  //                   subSelectedCount++;
  //                 }
  //               }
  //               if (subSelectedCount < subAttribute.minSelectedOptions) {
  //                 return {
  //                   isValid: false,
  //                   attributeName: `${detail.name} - ${subAttribute.name}`,
  //                 }; // If minSelectedOptions are not met for sub-attributes
  //               }
  //             }
  //           }
  //         }
  //       } else {
  //         // Checkboxes
  //         for (let option of detail.attributeOptions) {
  //           const checkbox = document.querySelector(
  //             `input[name="checkbox${option.id}"]:checked`
  //           ) as HTMLInputElement;
  //           if (checkbox) {
  //             selectedCount++;
  //           }
  //         }
  //       }
  //       if (selectedCount < detail.minSelectedOptions) {
  //         return { isValid: false, attributeName: detail.name }; // If minSelectedOptions are not met
  //       }
  //     }

  //     // Check maxSelectedOptions for radio buttons or checkboxes
  //     selectedCount = 0;
  //     if (
  //       detail.maxSelectedOptions !== undefined &&
  //       detail.maxSelectedOptions !== null
  //     ) {
  //       if (detail.type === 0) {
  //         // Radio buttons
  //         const selectedRadio = document.querySelector(
  //           `input[name="radiofirst${detail.id}"]:checked`
  //         ) as HTMLInputElement;
  //         if (selectedRadio) {
  //           selectedCount++;
  //         }
  //       } else if (detail.type === 1) {
  //         // Quantity-based options
  //         for (let option of detail.attributeOptions) {
  //           const optionQuantity =
  //             this.optionQuantities[detail.id]?.[option.id] || 0;
  //           selectedCount += optionQuantity;
  //         }
  //       } else if (detail.type === 2) {
  //         // Radio buttons with sub-attributes
  //         const selectedRadio = document.querySelector(
  //           `input[name="radiotree${detail.id}"]:checked`
  //         ) as HTMLInputElement;
  //         if (selectedRadio) {
  //           selectedCount++;
  //           const selectedOption = detail.attributeOptions.find(
  //             (option) => option.id == selectedRadio.value
  //           );
  //           if (selectedOption) {
  //             for (let subAttribute of selectedOption.subAttributes) {
  //               let subSelectedCount = 0;
  //               for (let subOption of subAttribute.attributeOptions) {
  //                 const selectedSubOption =
  //                   (document.querySelector(
  //                     `input[name="subRadiotree${subOption.id}"]:checked`
  //                   ) as HTMLInputElement) ||
  //                   (document.querySelector(
  //                     `input[name="subCheckboxtree${subOption.id}"]:checked`
  //                   ) as HTMLInputElement);
  //                 if (selectedSubOption) {
  //                   subSelectedCount++;
  //                 }
  //               }
  //               if (subSelectedCount > subAttribute.maxSelectedOptions) {
  //                 return {
  //                   isValid: false,
  //                   attributeName: `${detail.name} - ${subAttribute.name}`,
  //                 }; // If maxSelectedOptions are exceeded for sub-attributes
  //               }
  //             }
  //           }
  //         }
  //       } else {
  //         // Checkboxes
  //         for (let option of detail.attributeOptions) {
  //           const checkbox = document.querySelector(
  //             `input[name="checkbox${option.id}"]:checked`
  //           ) as HTMLInputElement;
  //           if (checkbox) {
  //             selectedCount++;
  //           }
  //         }
  //       }
  //       if (selectedCount > detail.maxSelectedOptions) {
  //         return { isValid: false, attributeName: detail.name }; // If maxSelectedOptions are exceeded
  //       }
  //     }

  //     // Check required attributes for radio buttons or checkboxes
  //     if (detail.isRequired) {
  //       if (detail.type === 0) {
  //         // Radio buttons
  //         const selectedRadio = document.querySelector(
  //           `input[name="radiofirst${detail.id}"]:checked`
  //         ) as HTMLInputElement;
  //         if (!selectedRadio) {
  //           return { isValid: false, attributeName: detail.name }; // If required but not selected
  //         }
  //       } else if (detail.type === 1) {
  //         // Quantity-based options
  //         let atLeastOneSelected = false;
  //         for (let option of detail.attributeOptions) {
  //           const optionQuantity =
  //             this.optionQuantities[detail.id]?.[option.id] || 0;
  //           if (optionQuantity > 0) {
  //             atLeastOneSelected = true;
  //             break;
  //           }
  //         }
  //         if (!atLeastOneSelected) {
  //           return { isValid: false, attributeName: detail.name }; // If required but not selected
  //         }
  //       } else if (detail.type === 2) {
  //         // Radio buttons with sub-attributes
  //         const selectedRadio = document.querySelector(
  //           `input[name="radiotree${detail.id}"]:checked`
  //         ) as HTMLInputElement;
  //         if (!selectedRadio) {
  //           return { isValid: false, attributeName: detail.name }; // If required but not selected
  //         }
  //         const selectedOption = detail.attributeOptions.find(
  //           (option) => option.id == selectedRadio.value
  //         );
  //         if (selectedOption) {
  //           for (let subAttribute of selectedOption.subAttributes) {
  //             let subAtLeastOneSelected = false;
  //             for (let subOption of subAttribute.attributeOptions) {
  //               const selectedSubOption =
  //                 (document.querySelector(
  //                   `input[name="subRadiotree${subOption.id}"]:checked`
  //                 ) as HTMLInputElement) ||
  //                 (document.querySelector(
  //                   `input[name="subCheckboxtree${subOption.id}"]:checked`
  //                 ) as HTMLInputElement);
  //               if (selectedSubOption) {
  //                 subAtLeastOneSelected = true;
  //                 break;
  //               }
  //             }
  //             if (!subAtLeastOneSelected && subAttribute.isRequired) {
  //               return {
  //                 isValid: false,
  //                 attributeName: `${detail.name} - ${subAttribute.name}`,
  //               }; // If required but not selected for sub-attributes
  //             }
  //           }
  //         }
  //       } else {
  //         // Checkboxes
  //         let atLeastOneSelected = false;
  //         for (let option of detail.attributeOptions) {
  //           const checkbox = document.querySelector(
  //             `input[name="checkbox${option.id}"]:checked`
  //           ) as HTMLInputElement;
  //           if (checkbox) {
  //             atLeastOneSelected = true;
  //             break;
  //           }
  //         }
  //         if (!atLeastOneSelected) {
  //           return { isValid: false, attributeName: detail.name }; // If required but not selected
  //         }
  //       }
  //     }
  //   }

  //   return { isValid: true }; // If all conditions are met
  // }
  areConditionsMet(): { isValid: boolean; attributeName?: string } {
    for (let detail of this.attributeDetails) {
      let selectedCount = 0;

      // Check minSelectedOptions
      if (
        detail.minSelectedOptions !== undefined &&
        detail.minSelectedOptions !== null
      ) {
        if (detail.type === 0) {
          // Radio buttons
          const selectedRadio = document.querySelector(
            `input[name="radiofirst${detail.id}"]:checked`
          ) as HTMLInputElement;
          if (selectedRadio) {
            selectedCount++;
          }
        } else if (detail.type === 1) {
          // Quantity-based options
          for (let option of detail.attributeOptions) {
            const optionQuantity =
              this.optionQuantities[detail.id]?.[option.id] || 0;
            selectedCount += optionQuantity;
          }
        } else if (detail.type === 2) {
          // Radio buttons with sub-attributes
          const selectedRadio = document.querySelector(
            `input[name="radiotree${detail.id}"]:checked`
          ) as HTMLInputElement;
          if (selectedRadio) {
            selectedCount++;
            const selectedOption = detail.attributeOptions.find(
              (option) => option.id == selectedRadio.value
            );
            if (selectedOption) {
              for (let subAttribute of selectedOption.subAttributes) {
                let subSelectedCount = 0;
                for (let subOption of subAttribute.attributeOptions) {
                  const selectedSubOption =
                    (document.querySelector(
                      `input[name="subRadiotree${subOption.id}"]:checked`
                    ) as HTMLInputElement) ||
                    (document.querySelector(
                      `input[name="subCheckboxtree${subOption.id}"]:checked`
                    ) as HTMLInputElement);
                  if (selectedSubOption) {
                    subSelectedCount++;
                  }
                }
                if (subSelectedCount < subAttribute.minSelectedOptions) {
                  return {
                    isValid: false,
                    attributeName: `${detail.name} - ${subAttribute.name}`,
                  }; // If minSelectedOptions are not met for sub-attributes
                }
              }
            }
          }
        } else {
          // Checkboxes
          for (let option of detail.attributeOptions) {
            const checkbox = document.querySelector(
              `input[name="checkbox${option.id}"]:checked`
            ) as HTMLInputElement;
            if (checkbox) {
              selectedCount++;
            }
          }
        }
        if (selectedCount < detail.minSelectedOptions) {
          return { isValid: false, attributeName: detail.name }; // If minSelectedOptions are not met
        }
      }

      // Check maxSelectedOptions
      selectedCount = 0;
      if (
        detail.maxSelectedOptions !== undefined &&
        detail.maxSelectedOptions !== null
      ) {
        if (detail.type === 0) {
          // Radio buttons
          const selectedRadio = document.querySelector(
            `input[name="radiofirst${detail.id}"]:checked`
          ) as HTMLInputElement;
          if (selectedRadio) {
            selectedCount++;
          }
        } else if (detail.type === 1) {
          // Quantity-based options
          for (let option of detail.attributeOptions) {
            const optionQuantity =
              this.optionQuantities[detail.id]?.[option.id] || 0;
            selectedCount += optionQuantity;
          }
        } else if (detail.type === 2) {
          // Radio buttons with sub-attributes
          const selectedRadio = document.querySelector(
            `input[name="radiotree${detail.id}"]:checked`
          ) as HTMLInputElement;
          if (selectedRadio) {
            selectedCount++;
            const selectedOption = detail.attributeOptions.find(
              (option) => option.id == selectedRadio.value
            );
            if (selectedOption) {
              for (let subAttribute of selectedOption.subAttributes) {
                let subSelectedCount = 0;
                for (let subOption of subAttribute.attributeOptions) {
                  const selectedSubOption =
                    (document.querySelector(
                      `input[name="subRadiotree${subOption.id}"]:checked`
                    ) as HTMLInputElement) ||
                    (document.querySelector(
                      `input[name="subCheckboxtree${subOption.id}"]:checked`
                    ) as HTMLInputElement);
                  if (selectedSubOption) {
                    subSelectedCount++;
                  }
                }
                if (subSelectedCount > subAttribute.maxSelectedOptions) {
                  return {
                    isValid: false,
                    attributeName: `${detail.name} - ${subAttribute.name}`,
                  }; // If maxSelectedOptions are exceeded for sub-attributes
                }
              }
            }
          }
        } else {
          // Checkboxes
          for (let option of detail.attributeOptions) {
            const checkbox = document.querySelector(
              `input[name="checkbox${option.id}"]:checked`
            ) as HTMLInputElement;
            if (checkbox) {
              selectedCount++;
            }
          }
        }
        if (selectedCount > detail.maxSelectedOptions) {
          return { isValid: false, attributeName: detail.name }; // If maxSelectedOptions are exceeded
        }
      }

      // Check required attributes
      if (detail.isRequired) {
        if (detail.type === 0) {
          // Radio buttons
          const selectedRadio = document.querySelector(
            `input[name="radiofirst${detail.id}"]:checked`
          ) as HTMLInputElement;
          if (!selectedRadio) {
            return { isValid: false, attributeName: detail.name }; // If required but not selected
          }
        } else if (detail.type === 1) {
          // Quantity-based options
          let atLeastOneSelected = false;
          for (let option of detail.attributeOptions) {
            const optionQuantity =
              this.optionQuantities[detail.id]?.[option.id] || 0;
            if (optionQuantity > 0) {
              atLeastOneSelected = true;
              break;
            }
          }
          if (!atLeastOneSelected) {
            return { isValid: false, attributeName: detail.name }; // If required but not selected
          }
        } else if (detail.type === 2) {
          // Radio buttons with sub-attributes
          const selectedRadio = document.querySelector(
            `input[name="radiotree${detail.id}"]:checked`
          ) as HTMLInputElement;
          if (!selectedRadio) {
            return { isValid: false, attributeName: detail.name }; // If required but not selected
          }
          const selectedOption = detail.attributeOptions.find(
            (option) => option.id == selectedRadio.value
          );
          if (selectedOption) {
            for (let subAttribute of selectedOption.subAttributes) {
              let subAtLeastOneSelected = false;
              for (let subOption of subAttribute.attributeOptions) {
                const selectedSubOption =
                  (document.querySelector(
                    `input[name="subRadiotree${subOption.id}"]:checked`
                  ) as HTMLInputElement) ||
                  (document.querySelector(
                    `input[name="subCheckboxtree${subOption.id}"]:checked`
                  ) as HTMLInputElement);
                if (selectedSubOption) {
                  subAtLeastOneSelected = true;
                  break;
                }
              }
              if (!subAtLeastOneSelected && subAttribute.isRequired) {
                return {
                  isValid: false,
                  attributeName: `${detail.name} - ${subAttribute.name}`,
                }; // If required but not selected for sub-attributes
              }
            }
          }
        } else {
          // Checkboxes
          let atLeastOneSelected = false;
          for (let option of detail.attributeOptions) {
            const checkbox = document.querySelector(
              `input[name="checkbox${option.id}"]:checked`
            ) as HTMLInputElement;
            if (checkbox) {
              atLeastOneSelected = true;
              break;
            }
          }
          if (!atLeastOneSelected) {
            return { isValid: false, attributeName: detail.name }; // If required but not selected
          }
        }
      }
    }

    return { isValid: true }; // If all conditions are met
  }

  isTheTop() {
    let element = document.getElementById('shoppingBox78');
    if (element.scrollTop > 80) {
      console.log('rrr');
    } // rest of your code...
  }
  addNote() {
    this.isNote = !this.isNote;
  }
  fillOrders() {
    this.orders = this.settingService
      .getData('my_orders')
      .filter((o) => o.companyId == this.id);
  }
  ngOnInit(): void {
    this.id = Number(this.activatedRoute.snapshot.queryParamMap.get('id'));
    this.fav = this.activatedRoute.snapshot.queryParamMap.get('fav') === 'true';
    this.getData();
    this.fillOrders();
    console.log(getUniqueId(10));
    const scroller = document.querySelector('#content');
    // window.onscroll = () => {
    //   // let element = document.getElementById('shoppingBox78');
    //   // console.log(element.offsetHeight);
    //   console.log(this.myFunc());
    // };
    // scroller.addEventListener('scroll', (event) => {

    //   // console.log(element.offsetTop)
    //   // if(element.scrollTop > 80){
    //   //   console.log("rrr")
    //   // }
    // });
    // console.log(document.getElementsByClassName('shoppingBox'));
    this.getDeliveryCost();
  }
  myFunc() {
    return document.documentElement.scrollTop;
  }
  day_ago = '';
  getRelativeDate(dateString: string): { display: string; daysAgo: number } {
    const date = new Date(dateString);
    const currentDate = new Date();
    const dateDiff = currentDate.getTime() - date.getTime();
    const daysAgo = Math.floor(dateDiff / (1000 * 60 * 60 * 24));

    if (daysAgo === 1) {
      return { display: 'day ago', daysAgo };
    } else if (daysAgo > 1) {
      return { display: `days ago`, daysAgo };
    } else {
      return { display: 'Today', daysAgo };
    }
  }
  location: number[] = [];
  currentSlideIndex: number = 0;

  selectSlide(index: number) {
    this.currentSlideIndex = index;
    // Logic to select the slide in the carousel (you may need to use ViewChild to control the NgbCarousel)
  }
  addonProducts = [];
  getData() {
    this.catalogService.getCompanyFullDetails(this.id).subscribe((res) => {
      this.company = res.data;
      this.location = res.data.latLng.split(',').map(Number);
      this.companyReviews = res.data.reviews;
      this.companyProducts = res.data.categories.map((category) => ({
        id: category.id,
        name: category.name,
        image: category.image,
        products: category.products,
      }));
      res.data.categories.forEach((category) => {
        category.products.forEach((product) => {
          // Find the main image
          const mainImage = product.productImages.find((image) => image.isMain);
          console.log(mainImage);
          // Create a new object with the desired properties
          const extractedProduct = {
            id: product.id,
            name: product.name,
            price: product.price,
            image: mainImage ? mainImage.image : 'assets/img/shop-1.png',
          };

          // Push the new object to addonProducts
          this.addonProducts.push(extractedProduct);
        });
      });
      console.log(this.addonProducts);
      // this.addDynamicStyles();
    });
    // this.catalogService
    //   .getCompaniesCategoriesAndProducts(this.id)
    //   .subscribe((res) => {
    //     this.companyProducts = res.data;
    //   });
  }
  getCountryId(id) {
    localStorage.setItem('countryId', id);
  }
  getarr(length: number) {
    return new Array(Math.round(length));
  }
  getCompanyProductId(id) {
    console.log(id);
    let el = document.getElementById('shoppingBox' + id);
    el.scrollIntoView({ behavior: 'smooth' });
  }
  open(content: any, id) {
    this.modalService.open(content, {
      centered: true,
      windowClass: 'modal-max',
    });
  }

  // calculateFullTotal() {
  //   let quantity = +$('.input-group-field').val();
  //   this.fullPrice = 0;
  //   var total = 0;
  //   var extraDetails = '';

  //   // this.attributeDetails.forEach((detail) => {
  //   //   if (detail.type === 1) {
  //   //     detail.attributeOptions.forEach((option) => {
  //   //       const quantity = this.optionQuantities[detail.id][option.id] || 0;
  //   //       this.fullPrice += quantity * option.price;
  //   //     });
  //   //   }
  //   // });
  //   // Calculate total for radio button groups
  //   $('#addItemToBasket input[id^="radiofirst"]:checked').each(function () {
  //     total += parseInt($(this).val(), 10);
  //     var idVal = $(this).attr('id');
  //     extraDetails +=
  //       $("label[for='" + idVal + "']")
  //         .text()
  //         .trim() + ', ';
  //   });
  //   // Calculate total for checkbox groups
  //   // $('#addItemToBasket input[type="checkbox"]:checked').each(function () {
  //   //   total += parseInt($(this).val(), 10);
  //   //   extraDetails += $(this).parent().text().trim() + ', ';
  //   // });
  //   // Calculate total for main options (radiotree)
  //   // $('input[type="radio"][id^="radiotree"]:checked').each(function () {
  //   //   total += parseInt($(this).val(), 10);
  //   //   const idVal = $(this).attr('id');
  //   //   extraDetails +=
  //   //     $("label[for='" + idVal + "']")
  //   //       .text()
  //   //       .trim() + ', ';
  //   // });

  //   // Calculate total for sub-options (subRadiotree)
  //   $('input[type="radio"][id^="subRadiotree"]:checked').each(function () {
  //     total += parseInt($(this).val(), 10);
  //     const idVal = $(this).attr('id');
  //     extraDetails +=
  //       $("label[for='" + idVal + "']")
  //         .text()
  //         .trim() + ', ';
  //   });

  //   // Calculate total for sub-checkbox options (subCheckboxtree)
  //   $('input[type="checkbox"][id^="subCheckboxtree"]:checked').each(
  //     function () {
  //       total += parseInt($(this).val(), 10);
  //       const idVal = $(this).attr('id');
  //       extraDetails +=
  //         $("label[for='" + idVal + "']")
  //           .text()
  //           .trim() + ', ';
  //     }
  //   );
  //   // Calculate total for addons
  //   for (const addonId in this.addonQuantities) {
  //     const addon = this.addonProducts.find((a) => a.id === +addonId);
  //     if (addon) {
  //       total += addon.price * this.addonQuantities[addonId];
  //     }
  //   }
  //   this.fullPrice = (this.price + total) * quantity;
  //   extraDetails = this.removeBetweenParentheses(extraDetails)
  //     .trim()
  //     .slice(0, -2); //get all selected texts of attirbutes
  //   this.extraDetails = extraDetails.replace(' ,', ',');
  //   this.attributeDetails.forEach((detail) => {
  //     if (detail.type === 1) {
  //       let detailText = `${detail.name}: `;
  //       let hasSelectedOptions = false;
  //       detail.attributeOptions.forEach((option) => {
  //         const quantity = this.optionQuantities[detail.id][option.id] || 0;
  //         detailText += `${quantity} * ${option.name}, `;
  //         hasSelectedOptions = true;
  //         this.fullPrice += quantity * option.price;
  //       });
  //       if (hasSelectedOptions) {
  //         extraDetails += detailText.trim();
  //     }
  //     }
  //   });
  // }
  //   calculateFullTotal() {
  //     let quantity = +$('.input-group-field').val();
  //     this.fullPrice = 0;
  //     var total = 0;
  //     var extraDetails = '';

  //     // Calculate total for radio button groups
  //     // $('#addItemToBasket input[id^="radiofirst"]:checked').each(function () {
  //     //   total += parseInt($(this).val(), 10);
  //     //   var idVal = $(this).attr('id');
  //     //   extraDetails += $("label[for='" + idVal + "']").text().trim() + ', ';
  //     // });
  //     // Calculate total for radio button groups
  //     this.attributeDetails.forEach((detail) => {
  //       if (detail.type === 0) {
  //           $('#addItemToBasket input[name="radiofirst' + detail.id + '"]:checked').each(function () {
  //               total += parseInt($(this).val(), 10);
  //               let idVal = $(this).attr('id');
  //               extraDetails += `${detail.name}: ${$("label[for='" + idVal + "']").text().trim()}, `;
  //           });
  //       }
  //   });

  //     // Calculate total for sub-options (subRadiotree)
  //     $('input[type="radio"][id^="subRadiotree"]:checked').each(function () {
  //       total += parseInt($(this).val(), 10);
  //       const idVal = $(this).attr('id');
  //       extraDetails += $("label[for='" + idVal + "']").text().trim() + ', ';
  //     });

  //     // Calculate total for sub-checkbox options (subCheckboxtree)
  //     $('input[type="checkbox"][id^="subCheckboxtree"]:checked').each(function () {
  //       total += parseInt($(this).val(), 10);
  //       const idVal = $(this).attr('id');
  //       extraDetails += $("label[for='" + idVal + "']").text().trim() + ', ';
  //     });

  //     // Calculate total for addons
  //     for (const addonId in this.addonQuantities) {
  //       const addon = this.addonProducts.find((a) => a.id === +addonId);
  //       if (addon) {
  //         const addonQuantity = this.addonQuantities[addonId];
  //         total += addon.price * addonQuantity;
  //         extraDetails += `${addon.name} x${addonQuantity}, `;
  //       }
  //     }

  //     // Handle detail type 1 (quantity-based options)
  //     this.attributeDetails.forEach((detail) => {
  //       if (detail.type === 1) {
  //         let detailText = `${detail.name}: `;
  //         let hasSelectedOptions = false;
  //         detail.attributeOptions.forEach((option) => {
  //           const optionQuantity = this.optionQuantities[detail.id]?.[option.id] || 0;
  //           if (optionQuantity > 0) {
  //             hasSelectedOptions = true;
  //             detailText += `${optionQuantity} * ${option.name}, `;
  //           }
  //           total += optionQuantity * option.price;
  //         });
  //         if (hasSelectedOptions) {
  //           extraDetails += detailText.trim().slice(0, -1) + '. ';
  //         }
  //       }
  //     });

  //     this.fullPrice = (this.price + total) * quantity;

  //     // Clean up extraDetails by removing content within parentheses and trailing comma
  //     extraDetails = this.removeBetweenParentheses(extraDetails).trim().replace(/,\s*$/, '');
  //     this.extraDetails = extraDetails.replace(/,\s*$/, '');

  //     // Log the results for debugging
  //     console.log('Full Price:', this.fullPrice);
  //     console.log('Extra Details:', this.extraDetails);
  // }
  // calculateFullTotal() {
  //   let quantity = +$('.input-group-field').val();
  //   this.fullPrice = 0;
  //   let total = 0;
  //   let extraDetails = '';

  //   // Calculate total for radio button groups (type 0)
  //   this.attributeDetails.forEach((detail) => {
  //     if (detail.type === 0) {
  //       $(
  //         '#addItemToBasket input[name="radiofirst' + detail.id + '"]:checked'
  //       ).each(function () {
  //         total += parseInt($(this).val(), 10);
  //         let idVal = $(this).attr('id');
  //               let labelText = $("label[for='" + idVal + "']").text().trim().split(' (')[0];
  //               extraDetails += `${detail.name}: ${labelText}, `;
  //       });
  //     }
  //   });

  //   // Calculate total for main options (radiotree)
  //   $('input[type="radio"][id^="radiotree"]:checked').each(function () {
  //     total += parseInt($(this).val(), 10);
  //     let idVal = $(this).attr('id');
  //     // extraDetails += $("label[for='" + idVal + "']").text().trim() + '\n ';
  //   });

  //   // Calculate total for sub-options (subRadiotree)
  //   $('input[type="radio"][id^="subRadiotree"]:checked').each(function () {
  //     total += parseInt($(this).val(), 10);
  //     let idVal = $(this).attr('id');
  //     // extraDetails += $("label[for='" + idVal + "']").text().trim() + '\n ';
  //   });

  //   // Calculate total for sub-checkbox options (subCheckboxtree)
  //   $('input[type="checkbox"][id^="subCheckboxtree"]:checked').each(
  //     function () {
  //       total += parseInt($(this).val(), 10);
  //       let idVal = $(this).attr('id');
  //       // extraDetails += $("label[for='" + idVal + "']").text().trim() + '\n ';
  //     }
  //   );

  //   // Calculate total for addons

  //   // Handle detail type 2 (radio buttons with sub-attributes)
  //   this.attributeDetails.forEach((detail) => {
  //     if (detail.type === 1) {
  //       let detailText = `${detail.name}: `;
  //       let hasSelectedOptions = false;
  //       detail.attributeOptions.forEach((option) => {
  //         const optionQuantity =
  //           this.optionQuantities[detail.id]?.[option.id] || 0;
  //         if (optionQuantity > 0) {
  //           hasSelectedOptions = true;
  //           detailText += `${optionQuantity} * ${option.name}, `;
  //         }
  //         total += optionQuantity * option.price;
  //       });
  //       if (hasSelectedOptions) {
  //         extraDetails += detailText.trim().slice(0, -1) + '. ';
  //       }
  //     } else if (detail.type === 2) {
  //       $(`#addItemToBasket input[name="radiotree${detail.id}"]:checked`).each(function () {
  //           total += parseInt($(this).val(), 10);
  //           let idVal = $(this).attr('id');
  //           let mainOptionText = $("label[for='" + idVal + "']").text().trim().split(' (')[0];

  //           let subDetails = '';
  //           const subOptions = $(this).closest('.form-checkbox').next().find('input[name^="subRadiotree"]:checked, input[name^="subCheckboxtree"]:checked');
  //           subOptions.each(function () {
  //               let subIdVal = $(this).attr('id');
  //               subDetails += $("label[for='" + subIdVal + "']").text().trim().split(' (')[0] + ', ';
  //           });

  //           if (subDetails) {
  //               subDetails = `(${subDetails.slice(0, -2)})`; // Remove trailing comma and wrap in parentheses
  //           }
  //           extraDetails += `${detail.name}: ${mainOptionText} ${subDetails}, `;
  //       });
  //   }
  //   });
  //   let addOns = '';
  //   for (const addonId in this.addonQuantities) {
  //     addOns = 'Add-Ons : ';
  //     const addon = this.addonProducts.find((a) => a.id === +addonId);
  //     if (addon) {
  //       const addonQuantity = this.addonQuantities[addonId];
  //       total += addon.price * addonQuantity;
  //       addOns += `${addon.name} * ${addonQuantity}, `;
  //     }
  //   }
  //   this.fullPrice = (this.price + total) * quantity;
  //   extraDetails += addOns;
  //   // Clean up extraDetails by removing content within parentheses and trailing comma
  //   // extraDetails = this.removeBetweenParentheses(extraDetails)
  //   //   .trim()
  //   //   .replace(/,\s*$/, '');
  //   // this.extraDetails = extraDetails.replace(/,\s*$/, '');
  //    // Clean up extraDetails by removing trailing comma
  //    extraDetails = extraDetails.trim().replace(/,\s*$/, '');
  //    this.extraDetails = extraDetails.replace(/,\s*$/, '');
 

  //   // Log the results for debugging
  //   console.log('Full Price:', this.fullPrice);
  //   console.log('Extra Details:', this.extraDetails);
  // }
  calculateFullTotal() {
    let quantity = +$('.input-group-field').val();
    this.fullPrice = 0;
    let total = 0;
    let extraDetails = '';

    // Calculate total for radio button groups (type 0)
    this.attributeDetails.forEach((detail) => {
        if (detail.type === 0) {
            $(`#addItemToBasket input[name="radiofirst${detail.id}"]:checked`).each(function () {
                total += parseInt($(this).val(), 10);
                let idVal = $(this).attr('id');
                let labelText = $("label[for='" + idVal + "']").text().trim().split(' (')[0];
                extraDetails += `${detail.name}: ${labelText}, `;
            });
        }
    });

    extraDetails += '\n';

    // Calculate total for main options (radiotree)
    $('input[type="radio"][id^="radiotree"]:checked').each(function () {
        total += parseInt($(this).val(), 10);
        let idVal = $(this).attr('id');
    });

    extraDetails += '\n';

    // Calculate total for sub-options (subRadiotree)
    $('input[type="radio"][id^="subRadiotree"]:checked').each(function () {
        total += parseInt($(this).val(), 10);
        let idVal = $(this).attr('id');
    });

    extraDetails += '\n';

    // Calculate total for sub-checkbox options (subCheckboxtree)
    $('input[type="checkbox"][id^="subCheckboxtree"]:checked').each(function () {
        total += parseInt($(this).val(), 10);
        let idVal = $(this).attr('id');
    });

    extraDetails += '\n';

    // Handle detail type 1 (quantity-based options)
    this.attributeDetails.forEach((detail) => {
        if (detail.type === 1) {
            let detailText = `${detail.name}: `;
            let hasSelectedOptions = false;
            detail.attributeOptions.forEach((option) => {
                const optionQuantity = this.optionQuantities[detail.id]?.[option.id] || 0;
                if (optionQuantity > 0) {
                    hasSelectedOptions = true;
                    detailText += `${optionQuantity} * ${option.name}, `;
                    total += optionQuantity * option.price;
                }
            });
            if (hasSelectedOptions) {
                extraDetails += detailText.trim().slice(0, -1) + '. ';
            }
        } else if (detail.type === 2) {
            $(`#addItemToBasket input[name="radiotree${detail.id}"]:checked`).each(function () {
                total += parseInt($(this).val(), 10);
                let idVal = $(this).attr('id');
                let mainOptionText = $("label[for='" + idVal + "']").text().trim().split(' (')[0];

                let subDetails = '';
                const subOptions = $(this).closest('.form-checkbox').next().find('input[name^="subRadiotree"]:checked, input[name^="subCheckboxtree"]:checked');
                subOptions.each(function () {
                    let subIdVal = $(this).attr('id');
                    subDetails += $("label[for='" + subIdVal + "']").text().trim().split(' (')[0] + ', ';
                });

                if (subDetails) {
                    subDetails = `(${subDetails.slice(0, -2)})`; // Remove trailing comma and wrap in parentheses
                }
                extraDetails += `${detail.name}: ${mainOptionText} ${subDetails}, `;
            });
        }
    });

    extraDetails += '\n';

    // Calculate total for addons
    let addOns = '';
    for (const addonId in this.addonQuantities) {
        if (!addOns) addOns = 'Add-Ons: ';
        const addon = this.addonProducts.find((a) => a.id === +addonId);
        if (addon) {
            const addonQuantity = this.addonQuantities[addonId];
            total += addon.price * addonQuantity;
            addOns += `${addon.name} * ${addonQuantity}, `;
        }
    }

    this.fullPrice = (this.price + total) * quantity;

    if (addOns) {
        extraDetails += addOns;
    }

    // Clean up extraDetails by removing trailing comma
    extraDetails = extraDetails.trim().replace(/,\s*$/, '');
    this.extraDetails = extraDetails.replace(/,\s*$/, '');

    // Log the results for debugging
    console.log('Full Price:', this.fullPrice);
    console.log('Extra Details:', this.extraDetails);
}

 

removeBetweenParentheses(input: string): string {
    return input.replace(/\(([^)]*)\)/g, '()');
}


 
  removeTrailingSpaces(input: string): string {
    return input.trim();
  }
  toggleFavorite() {
    this.favoriteService
      .addCompanyToFavorites(this.id, !this.fav)
      .subscribe((res) => {
        if (res.isSuccess) this.fav = !this.fav;
      });
  }
  getDeliveryCost() {
    let latlng =
      localStorage.getItem('lat') + ',' + localStorage.getItem('lng');
    if (localStorage.getItem('lat') && localStorage.getItem('lng'))
      this.authService.getDeliveryCost(this.id, latlng).subscribe((res) => {
        this.cost = res.data;
        console.log(this.cost)
        localStorage.setItem('deliveryCost', res.data);
      });
  }
  plusMinusBtn(type, isBasket = false) {
    if (!isBasket) {
      let fieldName = $('.hollow').attr('data-field');
      const input =
        document.querySelector<HTMLInputElement>('.input-group-field');

      if (type == 1) {
        if (!isNaN(+input.value)) {
          console.log('t');
          console.log(+input.value - 1);
          // Decrease
          if (+input.value == 1) $('input[name=' + fieldName + ']').val(1);
          else $('input[name=' + fieldName + ']').val(+input.value - 1);
        } else {
          // Otherwise put a 1 there
          $('input[name=' + fieldName + ']').val(1);
        }
        this.calculateFullTotal();
      } else {
        if (!isNaN(+input.value)) {
          // Increment
          // console.log('t');
          // console.log(+input.value + 1);
          // $('input:checkbox.food-option:checked').each(function () {
          //   checkIsExistCheckBox = true;
          //   console.log(+$(this).val());
          //   // this.fullPrice +=
          //   //   (+input.value + 1) * val + (+input.value + 1) * this.price;
          // });
          // if (!checkIsExistCheckBox)
          //   this.fullPrice = this.price * (+input.value + 1);
          // this.fullPrice *=(+input.value + 1);
          $('input[name=' + fieldName + ']').val(+input.value + 1);
        } else {
          // Otherwise put a 0 there
          $('input[name=' + fieldName + ']').val(1);
        }
        this.calculateFullTotal();
      }
    } else {
      let fieldName = $('.hollow-basket').attr('data-field');
      const input = document.querySelector<HTMLInputElement>(
        '.input-group-field-basket'
      );

      if (type == 1) {
        if (!isNaN(+input.value)) {
          // Decrease
          if (+input.value == 1) $('input[name=' + fieldName + ']').val(1);
          else $('input[name=' + fieldName + ']').val(+input.value - 1);
        } else {
          // Otherwise put a 1 there
          $('input[name=' + fieldName + ']').val(1);
        }
      } else {
        if (!isNaN(+input.value)) {
          // Increment
          $('input[name=' + fieldName + ']').val(+input.value + 1);
        } else {
          // Otherwise put a 0 there
          $('input[name=' + fieldName + ']').val(1);
        }
      }
    }
  }
  // getCheckedOptions
  makeOrderBtn() {
    console.log(window.innerWidth);
    if (window.innerWidth <= 992) {
      let mo = document.querySelector('#makeOrder');
      document.querySelector('#content').classList.toggle('d-flex');
      document.querySelector('#content').classList.toggle('d-none');
      document.querySelector('#sideBar').classList.toggle('showSideBar');
    }
  }
  keyupFun(event, index) {
    var characterCount = event.target.value.length;
    const current = document.querySelector<HTMLElement>('#current' + index);
    const maximum = document.querySelector<HTMLElement>('#maximum' + index);
    const theCount = document.querySelector<HTMLElement>('#the-count' + index);

    current.innerText = characterCount;
    if (characterCount < 70) {
      current.style.color = '#666';
    }
    if (characterCount > 70 && characterCount < 90) {
      current.style.color = '#6d5555';
    }
    if (characterCount > 90 && characterCount < 100) {
      current.style.color = '#793535';
    }
    if (characterCount > 100 && characterCount < 120) {
      current.style.color = '#841c1c';
    }
    if (characterCount > 120 && characterCount < 139) {
      current.style.color = '#8f0001';
    }

    if (characterCount >= 140) {
      maximum.style.color = '#8f0001';
      current.style.color = '#8f0001';
      theCount.style.fontWeight = 'bold';
    } else {
      maximum.style.color = '#666';
      theCount.style.fontWeight = 'normal';
    }
  }
}
