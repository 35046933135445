<app-navbar (GetCountryId)="getCountryId($event)"></app-navbar>
<div class="container my-4">
    <div class="w-100">
        <div class="d-flex">
            <h4 class="p-3 mb-0 text-style-right">{{order?.companyName}}</h4>
            <p [ngClass]="current_lang == 'en' ? 'ml-auto' : 'mr-auto'" class="p-3 mb-0 text-style-right">{{order?.orderDate | date:'medium'}}</p>
        </div>
        <h4 class="text-style-right"><span class="badge badge-pill badge-warning">{{'Order Number' | translate}} # {{order?.orderNumber}}</span>
        </h4>
        <div class="alert alert-info d-flex">
            <strong>{{order?.orderStatusName}}</strong>
            
          </div>
        <div class="card card-order-details mt-4">
            <div class="card-body p-0 table-responsive">
                <table class="table mb-0">
                    <thead>
                        <tr>
                            <th class="col-order-details text-style-right" scope="col">{{'Product Name' | translate}}
                            </th>
                            <th class="col-order-details text-style-right" scope="col"></th>
                            <th class="col-order-details text-style-right" scope="col">{{'Quantity' | translate}}</th>
                            <th class="col-order-details text-style-right" scope="col">{{'Price' | translate}}</th>
                            <th class="col-order-details text-style-right" scope="col">{{'Total' | translate}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="text-style-right" *ngFor="let detail of order?.orderDtls">
                            <th>
                                <img [src]="detail.imageUrl ? detail.imageUrl : '/assets/img/sidebanner-1.jpg'"
                                    alt="product" class="img-order-detail" width="80">
                            </th>
                            <td>{{detail.extraDetails}}</td>
                            <td>{{detail.quantity}}</td>
                            <td><strong>{{detail.price | currency : settingService.getCurrencySymbol()}}</strong></td>
                            <td><span class="badge badge-warning">{{detail.total | currency :
                                    settingService.getCurrencySymbol()}}</span></td>
                        </tr>
                        <tr>
                            <th class="d-flex" [colSpan]="4">
                                <span [ngClass]="current_lang == 'en' ? 'ml-1' : 'mr-1'" class="span-payment"><img
                                        src="/assets/img/Fa-Team-Fontawesome-FontAwesome-Money-Bill.512.png" width="20"
                                        alt=""> {{'Payment method' | translate}}: </span>
                                <span [ngClass]="current_lang == 'en' ? 'ml-1' : 'mr-1'" class="span-payment">
                                    {{order?.paymentTypeName}}</span>
                            </th>
                            <!-- <td>
                                <span class="text-muted">Total Price</span>
                                <strong>$100</strong>
                            </td> -->
                            <!-- <td>
                                <span class="text-muted">Total Paid</span>
                                <strong>$100</strong>
                            </td> -->
                            <!-- <td>
                                <span class="text-muted">Due</span>
                                <strong>$100</strong>
                            </td> -->
                            <td>
                            </td>
                            <td>
                            </td>
                            <td class="text-style-right">
                                <span class="text-muted span-payment">{{'SubTotal' | translate}}</span>
                            </td>
                            <td class="text-style-right" [colSpan]="2">
                                <!-- <span class="text-muted"></span> -->
                                <strong>{{order?.subTotal | currency : settingService.getCurrencySymbol()}}</strong>
                            </td>
                        </tr>
                        <tr class="payment-order-details text-style-right">
                            <th class="d-flex td-order-details" [colSpan]="4">
                                <!-- <span [ngClass]="current_lang == 'en' ? 'ml-1' : 'mr-1'" class="span-payment"><img src="/assets/img/Fa-Team-Fontawesome-FontAwesome-Money-Bill.512.png" width="20" alt=""> Payment Method: </span>
                                <span [ngClass]="current_lang == 'en' ? 'ml-1' : 'mr-1'" class="span-payment"> {{order?.paymentTypeName}}</span> -->
                            </th>
                            <td class="td-order-details">
                            </td>
                            <td class="td-order-details">
                            </td>
                            <td class="td-order-details">
                                <span class="text-muted span-payment">{{'Delivery'}}</span>
                            </td>
                            <td class="td-order-details" [colSpan]="2">
                                <!-- <span class="text-muted"></span> -->
                                <strong>{{order?.deliverFees | currency : settingService.getCurrencySymbol()}}</strong>
                            </td>
                        </tr>
                        <tr class="payment-order-details text-style-right">
                            <th class="d-flex td-order-details" [colSpan]="4">
                                <!-- <span [ngClass]="current_lang == 'en' ? 'ml-1' : 'mr-1'" class="span-payment"><img src="/assets/img/Fa-Team-Fontawesome-FontAwesome-Money-Bill.512.png" width="20" alt=""> Payment Method: </span>
                                <span [ngClass]="current_lang == 'en' ? 'ml-1' : 'mr-1'" class="span-payment"> {{order?.paymentTypeName}}</span> -->
                            </th>
                            <td class="td-order-details">
                            </td>
                            <td class="td-order-details">
                            </td>
                            <td class="td-order-details">
                                <span class="text-muted font-weight-bold">{{'Total' | translate}}</span>
                            </td>
                            <td class="td-order-details" [colSpan]="2">
                                <!-- <span class="text-muted"></span> -->
                                <strong>{{(order?.total) | currency :
                                    settingService.getCurrencySymbol()}}</strong>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div *ngIf="addReviewForm" class="comment-form">
        <div class="section-header-left">
            <h3 class="text-light-black header-title">{{'Leave a Reply' | translate}}</h3>
        </div>
        <form #addReview [formGroup]="addReviewForm"
        (ngSubmit)="createReview(addReviewForm.value,addReview)">
            <div class="row">
                <div class="col-md-12">
                    <p-rating [(ngModel)]="rate" [ngModelOptions]="{standalone: true}"></p-rating>
                </div>
                <div class="col-md-12">
                    <div class="form-group">
                        <label class="text-light-black fw-600">Your Comment</label>
                        <textarea formControlName="content" class="form-control form-control-submit" name="#" rows="6" placeholder="Your Comment"></textarea>
                    </div>
                    <button type="submit" class="btn-second btn-submit full-width">Send</button>
                </div>
            </div>
        </form>
    </div>
</div>


<app-footer2></app-footer2>