<div id='sideBarContent'>
  <h3 class='text-center mt-4'>{{'Basket' | translate}}</h3>
  <div class="mb-2" *ngFor="let order of orders;let i = index">
    <div class="d-flex">
      <span class="p-2 quantity-span">
        <input readonly class="input-group-field input-group-field-basket{{i}}" min="1" type="number"
          name="quantity{{i}}" [value]="order.quantity">
      </span>
      <p *ngIf="order.productName" class="p-2 d-flex flex-column">
        <a class="basket-product-title" href="">{{order.productName}}</a>
        <span class="option-width">
          {{order.extraDetails}}
        </span>
      </p>
      <span class="ml-auto p-2">
        {{ order?.total | currency : settingService.getCurrencySymbol() }}
      </span>
    </div>
    <div class="d-flex">
      <span class="p-2"></span>
      <a (click)="toggleNote(i)" class="add-note add-note{{i}}" *ngIf="!isNote[i]">{{ 'Add note' | translate}}</a>
      <a (click)="toggleNote(i)" class="add-note add-note{{i}}" *ngIf="isNote[i]">{{ 'Hide note'| translate}}</a>
      <div class="plus-minus-input d-flex ml-auto mx-2">
        <div class="input-group-button">
          <button (click)="plusMinusBtn(1,i,order)" type="button" class="button hollow-basket circle mx-2"
            data-quantity="minus" data-field="quantity">
            <i class="fa fa-minus" aria-hidden="true"></i>
          </button>
        </div>
        <div class="input-group-button pl-2">
          <button (click)="plusMinusBtn(2,i,order)" type="button" class="button hollow-basket circle"
            data-quantity="plus" data-field="quantity">
            <i class="fa fa-plus" aria-hidden="true"></i>
          </button>
        </div>
      </div>
    </div>
    <div *ngIf="isNote[i]" class="wrapper mt-1">
      <textarea [(ngModel)]="order.notes" maxlength="160" (keyup)="keyupFun($event,i)" name="" id="" cols="5" rows="2"></textarea>
      <div id="the-count{{i}}" class="the-count">
        <span id="current{{i}}">0</span>
        <span id="maximum{{i}}">/ 160</span>
      </div>
    </div>
  </div>
  <div *ngIf="orders.length > 0" class="d-flex mt-2">
    <div class="d-flex p-2 flex-column">
      <span>{{'SubTotal' | translate}}</span>
      <span>{{'DeliveryCosts' | translate}}</span>
      <h5 class="font-weight-bold">{{'Total' | translate}}</h5>
    </div>
    <div class="d-flex flex-column p-2 ml-auto">
      <span>{{subTotal() | currency : settingService.getCurrencySymbol()}}</span>
      <span>{{deliveryCost | currency : settingService.getCurrencySymbol()}}</span>
      <h5>{{orders[0].deliverFees + subTotal() + deliveryCost | currency : settingService.getCurrencySymbol()}}</h5>
    </div>
  </div>
  <div class="d-flex flex-column align-items-center pt-4" *ngIf="orders.length == 0">
    <p><img src="assets/img/shopping-bag.png" height="40" width="40" alt=""></p>
    <p class="font-weight-bold">{{'Fill Your Bag' | translate}}</p>
    <p>{{'Your bag is still empty' | translate}} 🙁</p>
  </div>
</div>

<!-- <button *ngIf="orders.length" [disabled]="minOrder > (orders[0].deliverFees + subTotal())" (click)="makeOrderBtn()" id='makeOrder' type="button" -->
<button *ngIf="orders.length" (click)="makeOrderBtn()" id='makeOrder' type="button"
  class="btn btn-warning pt-1 pb-2 w-100 mt-lg-5">
  <span>{{'Checkout' | translate}} (<span>{{orders[0].deliverFees + subTotal() + deliveryCost | currency : settingService.getCurrencySymbol()}}</span>)</span>
</button>