<!-- <div class="footer-top section-padding">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-2 col-sm-4 col-6 mb-sm-20">
                    <div class="icon-box"> <span class="text-light-green"><i class="flaticon-credit-card-1"></i></span>
                        <span class="text-light-black">100% Payment<br>Secured</span>
                    </div>
                </div>
                <div class="col-md-2 col-sm-4 col-6 mb-sm-20">
                    <div class="icon-box"> <span class="text-light-green"><i class="flaticon-wallet-1"></i></span>
                        <span class="text-light-black">Support lots<br> of Payments</span>
                    </div>
                </div>
                <div class="col-md-2 col-sm-4 col-6 mb-sm-20">
                    <div class="icon-box"> <span class="text-light-green"><i class="flaticon-help"></i></span>
                        <span class="text-light-black">24 hours / 7 days<br>Support</span>
                    </div>
                </div>
                <div class="col-md-2 col-sm-4 col-6">
                    <div class="icon-box"> <span class="text-light-green"><i class="flaticon-truck"></i></span>
                        <span class="text-light-black">Free Delivery<br>with $50</span>
                    </div>
                </div>
                <div class="col-md-2 col-sm-4 col-6">
                    <div class="icon-box"> <span class="text-light-green"><i class="flaticon-guarantee"></i></span>
                        <span class="text-light-black">Best Price<br>Guaranteed</span>
                    </div>
                </div>
                <div class="col-md-2 col-sm-4 col-6">
                    <div class="icon-box"> <span class="text-light-green"><i class="flaticon-app-file-symbol"></i></span>
                        <span class="text-light-black">Mobile Apps<br>Ready</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <footer class="section-padding bg-light-theme pt-0 u-line">
        <div class="u-line instagram-slider swiper-container">
            <ul class="hm-list hm-instagram swiper-wrapper">
                <li class="swiper-slide">
                    <a href="#"><img src="assets/img/restaurants/250x200/insta-3.jpg" alt="instagram"></a>
                </li>
                <li class="swiper-slide">
                    <a href="#"><img src="assets/img/restaurants/250x200/insta-1.jpg" alt="instagram"></a>
                </li>
                <li class="swiper-slide">
                    <a href="#"><img src="assets/img/restaurants/250x200/insta-2.jpg" alt="instagram"></a>
                </li>
                <li class="swiper-slide">
                    <a href="#"><img src="assets/img/restaurants/250x200/insta-4.jpg" alt="instagram"></a>
                </li>
                <li class="swiper-slide">
                    <a href="#"><img src="assets/img/restaurants/250x200/insta-5.jpg" alt="instagram"></a>
                </li>
                <li class="swiper-slide">
                    <a href="#"><img src="assets/img/restaurants/250x200/insta-6.jpg" alt="instagram"></a>
                </li>
                <li class="swiper-slide">
                    <a href="#"><img src="assets/img/restaurants/250x200/insta-7.jpg" alt="instagram"></a>
                </li>
                <li class="swiper-slide">
                    <a href="#"><img src="assets/img/restaurants/250x200/insta-8.jpg" alt="instagram"></a>
                </li>
            </ul>
        </div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-xl col-lg-4 col-md-4 col-sm-6">
                    <div class="footer-contact">
                        <h6 class="text-light-black">Need Help</h6>
                        <ul>
                            <li class="fw-600"><span class="text-light-white">Call Us</span> <a href="tel:" class="text-light-black">+(347) 123 456 789</a>
                            </li>
                            <li class="fw-600"><span class="text-light-white">Email Us</span> <a href="mailto:" class="text-light-black">demo@domain.com</a>
                            </li>
                            <li class="fw-600"><span class="text-light-white">Join our twitter</span> <a href="#" class="text-light-black">@quickmunch</a>
                            </li>
                            <li class="fw-600"><span class="text-light-white">Join our instagram</span> <a href="#" class="text-light-black">@quickmunch</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-xl col-lg-4 col-md-4 col-sm-6">
                    <div class="footer-links">
                        <h6 class="text-light-black">Get to Know Us</h6>
                        <ul>
                            <li><a href="#" class="text-light-white fw-600">About Us</a>
                            </li>
                            <li><a href="#" class="text-light-white fw-600">Blog</a>
                            </li>
                            <li><a href="#" class="text-light-white fw-600">Socialize</a>
                            </li>
                            <li><a href="#" class="text-light-white fw-600">Quickmunch</a>
                            </li>
                            <li><a href="#" class="text-light-white fw-600">Perks</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-xl col-lg-4 col-md-4 col-sm-6">
                    <div class="footer-links">
                        <h6 class="text-light-black">Let Us Help You</h6>
                        <ul>
                            <li><a href="#" class="text-light-white fw-600">Account Details</a>
                            </li>
                            <li><a href="#" class="text-light-white fw-600">Order History</a>
                            </li>
                            <li><a href="#" class="text-light-white fw-600">Find restaurant</a>
                            </li>
                            <li><a href="#" class="text-light-white fw-600">Login</a>
                            </li>
                            <li><a href="#" class="text-light-white fw-600">Track order</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-xl col-lg-4 col-md-4 col-sm-6">
                    <div class="footer-links">
                        <h6 class="text-light-black">Doing Business</h6>
                        <ul>
                            <li><a href="#" class="text-light-white fw-600">Suggest an Idea</a>
                            </li>
                            <li><a href="#" class="text-light-white fw-600">Be a Partner restaurant</a>
                            </li>
                            <li><a href="#" class="text-light-white fw-600">Create an Account</a>
                            </li>
                            <li><a href="#" class="text-light-white fw-600">Help</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-xl col-lg-4 col-md-4 col-sm-6">
                    <div class="footer-links">
                        <h6 class="text-light-black">Download Apps</h6>
                        <div class="appimg">
                            <a href="#">
                                <img src="assets/img/playstore.jpg" class="img-fluid" alt="app logo">
                            </a>
                        </div>
                        <div class="appimg">
                            <a href="#">
                                <img src="assets/img/appstore.jpg" class="img-fluid" alt="app logo">
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-xl col-lg-4 col-md-4 col-sm-6">
                    <div class="footer-contact">
                        <h6 class="text-light-black">Newsletter</h6>
                        <form class="subscribe_form">
                            <div class="input-group">
                                <input type="text" class="form-control form-control-submit" name="email" placeholder="Enter your email">
                                <span class="input-group-btn">
                      <button class="btn btn-second btn-submit" type="button"><i class="fas fa-paper-plane"></i></button>
                 </span>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="ft-social-media">
                        <h6 class="text-center text-light-black">Follow us</h6>
                        <ul>
                            <li> <a href="#"><i class="fab fa-facebook-f"></i></a>
                            </li>
                            <li> <a href="#"><i class="fab fa-twitter"></i></a>
                            </li>
                            <li> <a href="#"><i class="fab fa-instagram"></i></a>
                            </li>
                            <li> <a href="#"><i class="fab fa-pinterest-p"></i></a>
                            </li>
                            <li> <a href="#"><i class="fab fa-youtube"></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    <div class="copyright">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-4">
                    <div class="payment-logo mb-md-20"> <span class="text-light-white fs-14 mr-3">We are accept</span>
                        <div class="payemt-icon">
                            <img src="assets/img/card-front.jpg" alt="#">
                            <img src="assets/img/visa.jpg" alt="#">
                            <img src="assets/img/amex-card-front.png" alt="#">
                            <img src="assets/img/mastercard.png" alt="#">
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 text-center medewithlove align-self-center">
                    <a href="https://metropolitanhost.com/" class="text-light-black">Made with Real <i class="fas fa-heart"></i> Metropolitanthemes</a>
                </div>
                <div class="col-lg-4">
                    <div class="copyright-text"> <span class="text-light-black">© <a href="https://metropolitanhost.com/" class="text-light-white">Metropolitanthemes</a> - 2022 | All Right Reserved</span>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
<!-- <div class="footer-basic">
    <footer>
        <div class="social"><a href="#"><i class="fab fa-instagram"></i></a><a href="#"><i
                    class="fab fa-snapchat"></i></a><a href="#"><i class="fab fa-twitter"></i></a><a
                href="#"><i class="fab fa-facebook-f"></i></a></div>
        <ul class="list-inline">
            <li class="list-inline-item"><a href="#">{{'Home' | translate}}</a></li>
            <li class="list-inline-item"><a href="#">{{'Services' | translate}}</a></li>
            <li class="list-inline-item"><a href="#">{{'About' | translate}}</a></li>
            <li class="list-inline-item"><a href="#">{{'Terms' | translate}}</a></li>
            <li class="list-inline-item"><a href="#">{{'Privacy Policy' | translate}}</a></li>
        </ul>
        <p class="copyright">Rahhal Delivery © {{year}}</p>
    </footer>
</div> -->

<footer class="footer-section">
  <div class="container">
    <div class="footer-cta pt-5 pb-5">
      <div class="row">
        <div class="col-xl-4 col-md-4 mb-30">
          <div class="single-cta">
            <i class="fas fa-map-marker-alt"></i>
            <div class="cta-text">
              <h4>{{'Find us' | translate}}</h4>
              <span *ngIf="current_lang == 'en'">{{'MyAddress' | translate}}</span>
              <span *ngIf="current_lang == 'ar'">{{'MyAddress' | translate}}</span>
              <br *ngIf="current_lang == 'ar'">
              <span *ngIf="current_lang == 'ar'">{{'MyAddress1' | translate}}</span>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-md-4 mb-30">
          <div class="single-cta">
            <i class="fas fa-phone"></i>
            <div class="cta-text">
              <h4>{{'Call us' | translate}}</h4>
              <span>009651890999</span>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-md-4 mb-30">
          <div class="single-cta">
            <i class="far fa-envelope-open"></i>
            <div class="cta-text">
              <h4>{{'Mail us' | translate}}</h4>
              <span>info@rahaldelivery.com</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-content pt-5 pb-5">
      <div class="row">
        <div class="col-xl-4 col-lg-4 mb-50">
          <div class="footer-widget">
            <div class="footer-logo">
              <a routerLink="/"><img src="/assets/img/logo/logo-footer.png" class="img-fluid" alt="logo"></a>
            </div>
            <div class="footer-text">
              <p>{{'Check out exciting news about food and lifestyle on our blog.' | translate}}</p>
            </div>
            <div class="footer-social-icon">
              <span>{{'Follow us' | translate}}</span>
              <!-- <a href="#"><i class="fab fa-facebook-f facebook-bg"></i></a> -->
              <a target="_blank" href="https://www.facebook.com/profile.php?id=61553983692580"><i
                  class='bx bxl-facebook-circle bx-md'></i></a>
              <a target="_blank" href="https://twitter.com/i/flow/login?redirect_after_login=%2FRahal_DeliverKW"><i
                  class='bx bxl-twitter bx-md'></i></a>
              <!-- <a target="_blank" href="#"><i class='bx bxl-google-plus bx-md'></i></a> -->
              <a target="_blank" href="https://www.linkedin.com/company/rahal-technology"><i
                  class='bx bxl-linkedin bx-md'></i></a>
              <a target="_blank" href="https://www.tiktok.com/@rahal_delivery_kw?is_from_webapp=1&sender_device=pc"><i
                  class='bx bxl-tiktok bx-md'></i></a>
              <a target="_blank" href="https://www.instagram.com/rahal_deliverykw/"><i
                  class='bx bxl-instagram bx-md'></i></a>
              <a target="_blank" href=""><i class='bx bxl-snapchat bx-md'></i></a>
              <!-- <a href="#"><i class="fab fa-twitter twitter-bg"></i></a> -->
              <!-- <a href="#"><i class="fab fa-google-plus-g google-bg"></i></a> -->
              <!-- <a href="#"><i class='bx bxs-heart bx-border-circle bx-flashing bx-md'></i></a> -->
              <!-- <box-icon color="blue"  type='logo' name='linkedin'></box-icon> -->
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-6 mb-30">
          <div class="footer-widget">
            <div class="footer-widget-heading">
              <h3>{{'Useful Links' | translate}}</h3>
            </div>
            <ul>
              <li><a href="#">{{'Home' | translate}}</a></li>
              <li><a routerLink="/contact">{{'Contact' | translate}}</a></li>
              <li><a routerLink="/about-us">{{'About' | translate}}</a></li>
              <li><a href="https://merchant.rahaldelivery.com/register" target="_blank">{{'Be our partner' |
                  translate}}</a></li>
              <li><a routerLink="/privacy-policy">{{'Privacy Policy' | translate}}</a></li>
              <li><a routerLink="/https://merchant.rahaldelivery.com/">{{'Rahhal For Company' | translate}}</a></li>
              <li><a routerLink="/terms">{{'Terms of use' | translate}}</a></li>
              <!-- <li><a href="#">{{'Services' | translate}}</a></li> -->
              <!-- <li><a href="#">portfolio</a></li> -->
              <!-- <li><a routerLink="/about-us">{{'About us' | translate}}</a></li> -->
              <!-- <li><a href="#">{{'Our Services' | translate}}</a></li> -->
              <!-- <li><a href="#">Expert Team</a></li> -->
              <!-- <li><a routerLink="/contact">{{'Contact us' | translate}}</a></li> -->
              <!-- <li><a href="#">{{'Latest News' | translate}}</a></li> -->
            </ul>
          </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-6 mb-50">
          <div class="footer-widget">
            <!-- <div class="footer-widget-heading">
                <h3>{{'Subscribe' | translate}}</h3>
              </div> -->
            <div class="footer-widget-heading">
              <h3>{{'We are accept' | translate}}</h3>
            </div>
            <div class="footer-text mb-25">
              <!-- <a class="mx-2" href="javascript:void(0)"><img width="30" height="30" src="/assets/img/cards/credit-card.png"></a>
              <a class="mx-2 mt-4" href="javascript:void(0)"><img width="30" height="30" src="/assets/img/cards/cash.png"></a>
              <a class="mx-2" href="javascript:void(0)"><img width="30" height="30" src="/assets/img/cards/apple-pay.png"></a>
              <a class="mx-2" href="javascript:void(0)"><img width="30" height="30" src="/assets/img/cards/knet.png"></a>
              <a class="mx-2" href="javascript:void(0)"><img width="30" height="30" src="/assets/img/cards/pos.png"></a>
              <a class="mx-2" href="javascript:void(0)"><img width="30" height="30" src="/assets/img/cards/wallet.png"></a> -->
              <img class="mx-2" width="30" height="30" src="/assets/img/cards/credit-card.png">
              <img class="mx-2 mt-2" width="30" height="30" src="/assets/img/cards/cash.png">
              <img class="mx-2" width="30" height="30" src="/assets/img/cards/apple-pay.png">
              <img class="mx-2" width="30" height="30" src="/assets/img/cards/knet.png">
              <img class="mx-2" width="30" height="30" src="/assets/img/cards/pos.png">
              <img class="mx-2" width="30" height="30" src="/assets/img/cards/wallet.png">
            </div>
            <!-- <div class="subscribe-form">
                <form action="#">
                  <input type="text" placeholder="{{'Email Address' | translate}}">
                  <button><i class="fab fa-telegram-plane"></i></button>
                </form>
              </div> -->
            <!-- <div class="footer-widget-heading text-white mt-4 accept-style">
                <span class="style-h4">{{'We are accept' | translate}}</span>
              </div>
                <ul class="d-flex">
                  <li class="mt-2"><a href="javascript:void(0)"><img width="30" height="30" src="/assets/img/cards/credit-card.png"></a></li>
                  <li class="mt-2"><a href="javascript:void(0)"><img width="30" height="30" src="/assets/img/cards/cash.png"></a></li>
                  <li class="mt-2"><a href="javascript:void(0)"><img width="30" height="30" src="/assets/img/cards/apple-pay.png"></a></li>
                  <li class="mt-2"><a href="javascript:void(0)"><img width="30" height="30" src="/assets/img/cards/knet.png"></a></li>
                  <li class="mt-2"><a href="javascript:void(0)"><img width="30" height="30" src="/assets/img/cards/pos.png"></a></li>
                  <li class="mt-2"><a href="javascript:void(0)"><img width="30" height="30" src="/assets/img/cards/wallet.png"></a></li>
                </ul> -->
            <!-- <div class="">
                  <ul class="d-flex">
                    <li><a href="#"><img src="/assets/img/002-stopwatch.png"></a></li>
                    <li><a href="#"><img src="/assets/img/002-stopwatch.png"></a></li>
                    <li><a href="#"><img src="/assets/img/002-stopwatch.png"></a></li>
                    <li><a href="#"><img src="/assets/img/002-stopwatch.png"></a></li>
                    <li><a href="#"><img src="/assets/img/002-stopwatch.png"></a></li>
                  </ul>
              </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="copyright-area">
    <div class="container">
      <div class="row">
        <!-- <div class="col-lg-4">
            <div class="payment-logo mb-md-20"> <span class="text-light-white fs-14 mr-3">We are accept</span>
                <div class="payemt-icon">
                    <img class="mx-1" width="30" src="/assets/img/cards/credit-card.png" alt="#">
                    <img class="mx-1 mt-2" width="30" src="/assets/img/cards/cash.png" alt="#">
                    <img class="mx-1" width="30" src="/assets/img/cards/apple-pay.png" alt="#">
                    <img class="mx-1" width="30" src="/assets/img/cards/knet.png" alt="#">
                    <img class="mx-1" width="30" src="/assets/img/cards/pos.png" alt="#">
                    <img class="mx-1" width="30" src="/assets/img/cards/wallet.png" alt="#">
                </div>
            </div>
        </div> -->
        <div class="col-xl-6 col-lg-6 text-center text-lg-left">
          <div class="copyright-text">
            <p>{{'Copyright' | translate}} &copy; {{year}}, {{'All Right Reserved' | translate}} <a target="_blank"
                href="http://www.rahaltechnology.com/">{{'Rahal Technology' | translate}}</a></p>
          </div>
        </div>
        <!-- <div class="col-xl-6 col-lg-6 d-none d-lg-block text-right">
            <div class="footer-menu">
              <ul>
                <li><a href="javascript:void(0)"><img width="64" height="64" src="/assets/img/credit-card.png"></a></li>
                <li><a href="javascript:void(0)"><img width="64" height="64" src="/assets/img/002-stopwatch.png"></a></li>
                <li><a href="javascript:void(0)"><img width="64" height="64" src="/assets/img/002-stopwatch.png"></a></li>
                <li><a href="javascript:void(0)"><img width="64" height="64" src="/assets/img/apple-pay.png"></a></li>
                <li><a href="javascript:void(0)"><img width="64" height="64" src="/assets/img/wallet.png"></a></li>
              </ul>
            </div>
          </div> -->
      </div>
    </div>
  </div>
</footer>