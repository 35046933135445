import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CustomEncoder } from 'src/app/shared/custom-encoder';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { ResponseResult } from 'src/app/models-classes/common.model';
import {
  CustomerRegisterModel,
  LoginModel,
  LoginResponse,
} from 'src/app/models-classes/auth/login-model.model';
import {
  Address,
  EditAddress,
  Image,
  Review,
} from 'src/app/models-classes/address/address.model';
import { Order } from 'src/app/models-classes/order/order.model';
// import jwt_decode, { MyToken } from 'jwt-decode';
import jwt_decode from 'jwt-decode';
import { InterceptorSkipHeader } from 'src/app/shared/constants/global-variable.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private loginStatus = new BehaviorSubject<boolean>(this.checkLoginStatus());
  private userName = new BehaviorSubject<string>(
    localStorage.getItem('userName')
  );
  headers = new HttpHeaders().set(InterceptorSkipHeader, '');

  public userSubject: BehaviorSubject<LoginResponse>;
  public isLoggedIn: BehaviorSubject<boolean>;
  public orders: BehaviorSubject<Array<any>> = new BehaviorSubject([]);

  userOrders = this.orders.asObservable();

  updateUserData(data) {
    this.orders.next(data);
  }
  addData(dataObj) {
    const currentValue = this.orders.value;
    const updatedValue = [...currentValue, dataObj];
    this.orders.next(updatedValue);
  }
  public get userValue(): LoginResponse {
    return this.userSubject.value;
  }
  public get checkIsLoggedIn(): boolean {
    return this.isLoggedIn.value;
  }

  constructor(private _http: HttpClient) {
    // this.userSubject = new BehaviorSubject<LoginResponse>(null);
    // this.isLoggedIn = new BehaviorSubject<boolean>(false);
  }
  api_url = environment.api.baseUrl;
  login(loginModel: LoginModel): Observable<ResponseResult> {
    return this._http
      .post<ResponseResult>(this.api_url + '/Customer/Login', loginModel, {})
      .pipe(
        map((res) => {
          const response: ResponseResult = {
            data: null,
            errors: res.errors,
            isSuccess: false,
            statusCode: 400,
          };
          if (res && res.isSuccess) {
            response.isSuccess = res.isSuccess;
            const user: LoginResponse = {
              id: res.data.id,
              image: res.data.image,
              email: res.data.email,
              token: res.data.token,
              fullName: res.data.fullName,
              emailConfirmed: res.data.emailConfirmed,
            };
            response.data = user;
            // if (res.data.emailConfirmed) {
            //   localStorage.setItem('loginStatus', '1');
            //   localStorage.setItem('walletBalance', res.data.walletBalance);
            //   localStorage.setItem('fullName', res.data.fullName);
            //   localStorage.setItem('customerId', res.data.id);
            //   localStorage.setItem('user_image', res.data.image);
            //   localStorage.setItem('access_token', res.data.token);
            //   this.loginStatus.next(true);
            //   this.userName.next(res.data.fullName);
            // } else {
            //   localStorage.setItem('loginStatus', '0');
            //   this.loginStatus.next(false);
            //   this.userName.next('');
            //   return response;
            // }
            localStorage.setItem('loginStatus', '1');
            localStorage.setItem('walletBalance', res.data.walletBalance);
            localStorage.setItem('fullName', res.data.fullName);
            localStorage.setItem('customerId', res.data.id);
            localStorage.setItem('user_image', res.data.image);
            localStorage.setItem('access_token', res.data.token);
            this.loginStatus.next(true);
            this.userName.next(res.data.fullName);
            // this.userSubject.next(user);
            localStorage.setItem('current_user', JSON.stringify(user));
            // this.isLoggedIn.next(true);
            return response;
          } else {
            localStorage.setItem('loginStatus', '0');
            localStorage.setItem('walletBalance', 0 + '');
            localStorage.setItem('fullName', '');
            localStorage.setItem('customerId', '');
            localStorage.setItem('user_image', '');
            localStorage.setItem('access_token', '');
            this.loginStatus.next(false);
            this.userName.next('');
          }
          return response;
        })
      );
  }
  checkUserIsLogedIn(): boolean {
    var loginCookie = localStorage.getItem('loginStatus');

    if (loginCookie == '1') {
      if (
        localStorage.getItem('access_token') === null ||
        localStorage.getItem('access_token') === undefined
      ) {
        return false;
      }
      // Get and Decode the Token
      const token = localStorage.getItem('access_token');
      const decoded = jwt_decode<MyToken>(token);
      // Check if the cookie is valid

      if (decoded.exp === undefined) {
        return false;
      }

      // Get Current Date Time
      const date = new Date(0);

      // Convert EXp Time to UTC
      let tokenExpDate = date.setUTCSeconds(decoded.exp);

      // If Value of Token time greter than

      if (tokenExpDate.valueOf() > new Date().valueOf()) {
        return true;
      }

      // console.log('NEW DATE ' + new Date().valueOf());
      // console.log('Token DATE ' + tokenExpDate.valueOf());

      return false;
    }
    return false;
  }
  getUserName(): string {
    return localStorage.getItem('fullName') || '';
  }
  serUserName(name: string) {
    localStorage.setItem('fullName', name);
  }
  getShopTypeId(): number {
    return +localStorage.getItem('shopTypeId') || 0;
  }
  getCountryCode(): string {
    return localStorage.getItem('countryCode') == 'undefined' ||
      localStorage.getItem('countryCode') == ''
      ? ''
      : localStorage.getItem('countryCode');
  }
  getCustomerId(): string {
    return localStorage.getItem('customerId') || '';
  }
  getLang(): string {
    return localStorage.getItem('current_lang') || '';
  }
  logout() {
    localStorage.removeItem('current_user');
    localStorage.removeItem('address');
    localStorage.removeItem('customerId');
    localStorage.removeItem('access_token');
    localStorage.removeItem('loginStatus');
    localStorage.removeItem('fullName');
    localStorage.removeItem('provider');
    localStorage.removeItem('user_image');
    // this.userSubject.next(null);
    // this.isLoggedIn.next(false);
    localStorage.setItem('loginStatus', '0');
    this.loginStatus.next(false);
    this.userName.next('');
  }
  checkLoginStatus(): boolean {
    var loginCookie = localStorage.getItem('loginStatus');
    if (loginCookie == '1') {
      return true;
    }
    return false;
  }
  get isUserLoggedIn() {
    return this.loginStatus.asObservable();
  }
  get currentUserName() {
    return this.userName.asObservable();
  }
  confirmEmail(email: string, otp: string): Observable<ResponseResult> {
    let params = new HttpParams({ encoder: new CustomEncoder() });
    params = params.append('email', email);
    params = params.append('otp', otp);
    return this._http.get<ResponseResult>(
      this.api_url + '/Customer/ConfirmEmail',
      { params: params }
    );
  }
  customerRegister(model: CustomerRegisterModel): Observable<ResponseResult> {
    return this._http.post<ResponseResult>(
      this.api_url + '/Customer/CustomerRegister',
      model,
      {}
    );
  }
  customerInfo(): Observable<ResponseResult> {
    return this._http.get<ResponseResult>(
      this.api_url + '/Customer/CustomerInfo',
      {}
    );
  }
  payment(encrp: string, errorCode: string = ''): Observable<ResponseResult> {
    return this._http.get<ResponseResult>(
      this.api_url +
        `/Payment/GetTransactions?encrp=${encrp}&ErrorCode=${errorCode}`,
      {}
    );
  }
  createOrder(model: Order): Observable<ResponseResult> {
    return this._http.post<ResponseResult>(
      this.api_url + `/Customer/CreateOrder`,
      model,
      {}
    );
  }
  getAllOrders(): Observable<ResponseResult> {
    return this._http.get<ResponseResult>(
      this.api_url + '/Customer/GetAllOrders'
    );
  }
  getOrderDetails(orderId: number): Observable<ResponseResult> {
    return this._http.get<ResponseResult>(
      this.api_url + '/Customer/GetOrderDetails?orderId=' + orderId
    );
  }
  getDeliveryCost(
    companyId: number,
    latlng: string
  ): Observable<ResponseResult> {
    return this._http.get<ResponseResult>(
      this.api_url +
        '/Customer/GetDeliveryCost?branchId=' +
        companyId +
        '&latlng=' +
        latlng,
      { headers: this.headers }
    );
  }
  getLastAddedAddresses(): Observable<ResponseResult> {
    return this._http.get<ResponseResult>(
      this.api_url + '/Customer/GetLastAddedAddress',
      {}
    );
  }
  getAddresses(header: any = {}): Observable<ResponseResult> {
    return this._http.get<ResponseResult>(
      this.api_url + '/Customer/GetAddresses',
      { headers: header }
    );
  }
  getMyWallet(): Observable<ResponseResult> {
    return this._http.get<ResponseResult>(
      this.api_url + '/Customer/GetMyWalletTransactions',
      {}
    );
  }
  deleteAddress(addressId: number): Observable<ResponseResult> {
    return this._http.post<ResponseResult>(
      this.api_url + `/Customer/DeleteAddress?addressId=${addressId}`,
      {},
      {}
    );
  }
  editAddress(model: Address): Observable<ResponseResult> {
    return this._http.post<ResponseResult>(
      this.api_url + `/Customer/EditAddress`,
      model,
      {}
    );
  }
  addAddress(model: Address): Observable<ResponseResult> {
    return this._http.post<ResponseResult>(
      this.api_url + `/Customer/AddAddress`,
      model,
      {}
    );
  }
  myReviews(): Observable<ResponseResult> {
    return this._http.get<ResponseResult>(
      this.api_url + '/Customer/MyReviews',
      {}
    );
  }
  addReview(model: Review): Observable<ResponseResult> {
    return this._http.post<ResponseResult>(
      this.api_url + `/Customer/AddReview`,
      model,
      {}
    );
  }
  contact(model: any): Observable<ResponseResult> {
    return this._http.post<ResponseResult>(
      this.api_url + `/Customer/ContactUs`,
      model,
      {}
    );
  }
  deleteReview(reviewId): Observable<ResponseResult> {
    return this._http.post<ResponseResult>(
      this.api_url + '/Customer/UpdateFullName?reviewId=' + reviewId,
      {},
      {}
    );
  }
  updateFullName(fullName: string): Observable<ResponseResult> {
    return this._http.post<ResponseResult>(
      this.api_url + '/Customer/UpdateFullName?fullName=' + fullName,
      {},
      {}
    );
  }
  updateProfileImage(image: Image): Observable<ResponseResult> {
    return this._http.post<ResponseResult>(
      this.api_url + '/Customer/UpdateProfileImage',
      image
    );
  }
  changePassword(
    oldPassword: string,
    newPassword: string
  ): Observable<ResponseResult> {
    return this._http.post<ResponseResult>(
      this.api_url +
        `/Customer/ChangePassword?oldPassword=${oldPassword}&newPassword=${newPassword}`,
      {},
      {}
    );
  }
  resendOtp(email: string): Observable<ResponseResult> {
    return this._http.get<ResponseResult>(
      this.api_url + `/Customer/ResendOTP?email=${email}`,
      {}
    );
  }
  sendResetPasswordConfirmation(email: string): Observable<ResponseResult> {
    return this._http.post<ResponseResult>(
      this.api_url + `/Customer/SendResetPasswordConfirmation?email=${email}`,
      {},
      {}
    );
  }
  resetPassword(
    email: string,
    newPassword: string,
    key: string
  ): Observable<ResponseResult> {
    return this._http.post<ResponseResult>(
      this.api_url +
        `/Customer/ResetPassword?email=${email}&newPassword=${newPassword}&code=${key}`,
      {},
      {}
    );
  }
}
interface MyToken {
  name: string;
  exp: number;
  // whatever else is in the JWT.
}
