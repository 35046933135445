<!-- <section class="browse-cat-100 u-line section-padding2 header">
  <div class="container">
    <div class="row">
      <div *ngIf="company_categories.length == 0" class="col-12">
        <div class="item">
          <ngx-skeleton-loader count="1" animation="progress" [theme]="{
            'border-radius': '5px',
            height: '40px',
            border: '1px solid white'
          }"></ngx-skeleton-loader>
        </div>
      </div>
      <div class="col-12">
        <div class="category-slider swiper-container swiper-container3">
          <div class="swiper-wrapper">
              <ng-container *ngFor="let item of company_categories; let i=index">
                <div class="swiper-slide">
                  <a [id]="'cat' + i" (click)="toggleStyle(i,item.id)" href="javascript:void(0)" class="categories">
                    <span class="text-light-black cat-name">{{item.name}}</span>
                  </a>
                </div>
              </ng-container>
          </div>
          <div class="swiper-button-next swiper-button-next2"></div>
          <div class="swiper-button-prev swiper-button-prev2"></div>
        </div>
      </div>
    </div>
  </div>
</section> -->
<!-- <div class="category-slider">
  <div class="swiper-wrapper">
    <ng-container *ngFor="let item of company_categories; let i=index">
      <div class="swiper-slide">
        <a [class.category-style-company-page]="i == 0" [id]="'cat' + i" (click)="toggleStyle(i,item.id)" href="javascript:void(0)" class="categories">
          <span [ngClass]="[i == 0 ? 'cat-color' : 'cat-color-black']" class="text-light-black company-product-name">{{item.name}}</span>
        </a>
      </div>
    </ng-container>
  </div>
  <div class="swiper-pagination"></div>

  <div class="swiper-button-next swiper-button-next2"></div>
  <div class="swiper-button-prev swiper-button-prev2"></div>
</div> -->


<!-- <div id="nav-com">
  <div id="nav" class="row browse-cat-100 u-line section-padding2">
    <div class="col-md-12 mt-2">
      <div class="swiper-container3 category-slider">
        <div class="swiper-wrapper">
          <ng-container *ngFor="let item of company_categories; let i=index">
            <div class="swiper-slide swiper-slide-custom mx-2">
              <a [class.category-style-company-page]="i == 0" [id]="'cat' + i" (click)="toggleStyle(i,item.id)"
                href="javascript:void(0)" class="categories">
                <span [ngClass]="[i == 0 ? 'cat-color' : 'cat-color-black']"
                  class="text-light-black company-product-name">{{item.name}}</span>
              </a>
            </div>
          </ng-container>
        </div>
        <div class="swiper-pagination"></div>
        <div class="swiper-button-next swiper-button-next2"></div>
        <div class="swiper-button-prev swiper-button-prev2"></div>
      </div>
    </div>
  </div>
</div> -->


<ng-container *ngIf="company_categories.length == 0" class="swiper-slide">
  <ngx-skeleton-loader count="1" animation="progress" [theme]="{
      'border-radius': '5px',
      height: '40px',
      border: '1px solid white'
    }"></ngx-skeleton-loader>
</ng-container>

<div class="popular-item-slider instagram-slider">
  <div class="swiper-wrapper">
    <div class="swiper-slide" *ngFor="let item of company_categories; let i=index">
      <a class="categories" [id]="'cat' + i" (click)="toggleStyle(i,item.id)" href="javascript:void(0)">
        <p class="icon text-custom-white">
          <img [src]="item.image ? item.image : 'assets/img/restaurants/125x125/cuisine-11.jpg'" class="rounded-circle" alt="categories">
        </p>
        <span class="text-light-black cat-name">{{ item.name }}</span>
      </a>
    </div>
  </div>
  <!-- Add Arrows -->
  <div class="swiper-button-next"></div>
  <div class="swiper-button-prev"></div>
</div>
