<app-navbar></app-navbar>
<section class="page_404">
	<div class="container">
		<div class="row">	
		<div class="col-sm-12 ">
		<div class="col-sm-10 col-sm-offset-1  text-center">
		<div class="four_zero_four_bg">
			<h1 class="text-center ">404</h1>
		
		
		</div>
		
		<div class="contant_box_404">
            <h3>{{'the page you are looking for not avaible' | translate}}</h3>
		<a href="" class="link_404">{{'Back to Home' | translate}}</a>
	</div>
		</div>
		</div>
		</div>
	</div>
</section>
<!-- <section class="section-padding errorpage">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-7 text-center">
                <img src="assets/img/banner/banner-2.jpg" alt="img" />
            </div>
            <div class="col-lg-5 text-center">
                <div class="error-texts relative">
                    <h1>404</h1>
                    <h3>Ooops! That page doesn't exist!</h3>
                    <p>This file May Have Been Moved or Deleted. Be Sure to Check Your Spelling.</p>
                    <a routerLink="/" class="btn-second btn-submit">Back to Home</a>
                </div>
            </div>
        </div>
    </div>
</section> -->
<app-footer1></app-footer1>