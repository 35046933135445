import { Component, OnInit } from '@angular/core';
import {
  FormGroupDirective,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Review } from 'src/app/models-classes/address/address.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.css'],
})
export class OrderDetailsComponent implements OnInit {
  addReviewForm: UntypedFormGroup;
  current_lang = localStorage.getItem('current_lang') || 'en';
  order: any;
  id = 0;
  rate = 0;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    public settingService: SettingsService,
    private toastr: ToastrService,
    private fb: UntypedFormBuilder,
    private translateService:TranslateService
  ) {}

  ngOnInit(): void {
    this.id = Number(this.activatedRoute.snapshot.queryParamMap.get('id'));
    this.getOrder();
  }

  setReviewForm() {
    this.addReviewForm = this.fb.group({
      content: [''],
    });
  }
  getOrder() {
    this.authService.getOrderDetails(this.id).subscribe((res) => {
      this.order = res.data;
      if (this.order.orderStatus == 4) {
        this.setReviewForm();
      }
      console.log(this.order);
    });
  }
  getCountryId(id) {
    localStorage.setItem('countryId', id);
  }
  createReview(data, formDirective: FormGroupDirective) {
    if (!this.addReviewForm.valid) {
      this.toastr.error(this.translateService.instant('Please check all fields'));
      return;
    }
    if(this.rate == 0){
      this.toastr.warning(this.translateService.instant('The field Rate must be between 1 and 5.'))
      return;
    }
    let review: Review = {
      rate: this.rate ? this.rate : 0,
      content: data.content,
      reviewDate: new Date(),
      companyId: this.order.companyId,
      orderId: this.id,
    };
    console.log(review);
    this.authService.addReview(review).subscribe((res) => {
      if (res.isSuccess) {
        this.toastr.success(this.translateService.instant('Evaluation completed successfully'));
      } else {
        const translatedErrors = res.errors.map((error) =>
          this.translateService.instant(error)
        );
        const errorMessage = translatedErrors.join('\n');
        this.toastr.error(errorMessage);
        // let errorMessage = res.errors.join('\n'); // Using newline for better readability
        // this.toastr.error(errorMessage);
      }
    });
  }
}
