import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseResult } from 'src/app/models-classes/common.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class InfoService {
  _base = environment.api.baseUrl;
  constructor(private _http: HttpClient) {}

  getAbout(): Observable<ResponseResult> {
    return this._http.get<ResponseResult>(this._base + '/api/Info/GetAbout');
  }

  getTerms(): Observable<ResponseResult> {
    return this._http.get<ResponseResult>(this._base + '/api/Info/GetTerms');
  }

  getPrivacy(): Observable<ResponseResult> {
    return this._http.get<ResponseResult>(this._base + '/api/Info/GetPrivacy');
  }
}
