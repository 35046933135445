import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth/auth.service';
import { LookupsService } from 'src/app/services/lookups/lookups.service';
// import * as $ from 'jquery';
declare var $: any;

import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { SettingsService } from 'src/app/services/settings.service';
import { Inject } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { FavoriteService } from 'src/app/services/favorites/favorite.service';
// import { SocialAuthService } from '@abacritt/angularx-social-login';
import { Location } from '@angular/common';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class NavbarComponent implements OnInit, AfterViewInit {
  loginStatus$ = new BehaviorSubject<boolean>(null);
  userLoginStatus$: Observable<boolean>;
  userName$: Observable<string>;
  langs = {
    en: 'English',
    ar: 'Arabic',
  };
  searchAddress: string = '';
  lat = +localStorage.getItem('lat');
  lng = +localStorage.getItem('lng');
  myFavoritesCompanies: any[] = [];
  myFavoritesProducts: any[] = [];
  @Output() SetLatLng = new EventEmitter();
  @ViewChild('autocompleteInput') autocompleteInput: ElementRef;
  autoComplete: google.maps.places.Autocomplete;

  @Input() address;
  updateInputValue(): void {
    const inputElement: HTMLInputElement = this.autocompleteInput.nativeElement;
    inputElement.value = ''; // Clear input value
    setTimeout(() => {
      inputElement.dispatchEvent(new Event('input')); // Trigger input event
    });
  }

  address2 = localStorage.getItem('address');
  countries: any[] = [];
  @Output() GetCountryId = new EventEmitter();
  constructor(
    public _authService: AuthService,
    private _router: Router,
    private modalService: NgbModal,
    public translate: TranslateService,
    @Inject(DOCUMENT) private document: Document,
    public settings: SettingsService,
    private lookupsService: LookupsService,
    private favoriteService: FavoriteService,
    private authService: AuthService,
    private cdref: ChangeDetectorRef,
    private _location: Location // private authService: SocialAuthService
  ) {}
  ngAfterViewInit(): void {
    console.log(this.options);
    console.log(this.authService.getCountryCode());
    this.flagImage = localStorage.getItem('flagImage');
    this.cdref.detectChanges();
  }
  userImg =
    localStorage.getItem('user_image') &&
    localStorage.getItem('user_image') != 'null'
      ? localStorage.getItem('user_image')
      : 'assets/img/user-1.png';
  countryName = localStorage.getItem('countryName') || 'الاردن';
  flagImage = '';
  objectArray: any;
  open(content: any) {
    this.modalService
      .open(content, {
        centered: true,
        windowClass: 'modal-max',
      })
  }
  options: any;
  backClicked() {
    this._location.back();
  }
  
  handleAddressChange(address: google.maps.places.PlaceResult) {
    this.address = address.formatted_address;
    this.lng = address.geometry.location.lng();
    this.lat = address.geometry.location.lat();
    localStorage.setItem('newAddress', 'true');
    localStorage.setItem('address', this.address);
    localStorage.setItem('lng', this.lng.toString());
    localStorage.setItem('lat', this.lat.toString());
    this.modalService.dismissAll();
    this.SetLatLng.emit({ lat: this.lat, lng: this.lng });
  }
  checkUrl = true;
  check = false;
  // initAutocomplete(): void {
  //   // Check if searchInputRef is defined before accessing its nativeElement
  //   this.autoComplete = new google.maps.places.Autocomplete(
  //     this.autocompleteInput.nativeElement,
  //     {
  //       componentRestrictions: {
  //         country: this.authService.getCountryCode() || 'kw',
  //       }, // Default country
  //     }
  //   );
  //   this.autoComplete.addListener(
  //     'place_changed',
  //     this.handlePlaceChanged.bind(this)
  //   );
  //   this.autoComplete.setOptions({
  //     componentRestrictions: { country: this.authService.getCountryCode() },
  //   });
  // }
  ngOnInit(): void {
    this.userLoginStatus$ = this._authService.isUserLoggedIn;
    this.userName$ = this._authService.currentUserName;
    this.objectArray = [
      { id: 1, name: 'Angular' },
      { id: 2, name: 'Typescript' },
      { id: 3, name: 'Javascript' },
      { id: 4, name: 'HTML' },
      { id: 5, name: 'Java' },
      { id: 6, name: 'dotnet' },
    ];
    this.options = {
      componentRestrictions: {
        country: this.authService.getCountryCode() || 'kw',
      },
    };
    this.getCountries();
    this.checkUrl = this._router.url == '/';
    setTimeout(() => {
      this.autoComplete = new google.maps.places.Autocomplete(
        this.autocompleteInput.nativeElement,
        {
          componentRestrictions: {
            country: this.authService.getCountryCode() || 'jo',
          }, // Default country
        }
      );
      this.autoComplete.addListener('place_changed', this.handlePlaceChanged.bind(this));

    }, 1000);
    // this.initAutocomplete()
  }
  navChanged(event) {
    this.getFavorites(event.nextId);
    // window.scrollTo({ top: 0 });
    // this.nav_number = event.nextId;
    // this.pageIndex = 1;
    // if (event.nextId == 1) this.globalFilter(1);
    // else this.globalFilter(2);
  }
  getFav() {
    this.getFavorites(1);
  }
  getFavorites(type) {
    this.myFavoritesCompanies = [];
    this.myFavoritesProducts = [];
    if (this._authService.getShopTypeId()) {
      if (type == 1) {
        this.favoriteService
          .myFavoriteCompanies(this._authService.getShopTypeId())
          .subscribe((res) => {
            console.log(this.myFavoritesCompanies);
            this.myFavoritesCompanies = res.data;
            console.log(this.myFavoritesCompanies);
          });
      } else {
        this.favoriteService
          .myFavoriteProducts(this._authService.getShopTypeId())
          .subscribe((res) => {
            this.myFavoritesProducts = res.data;
          });
      }
    }
  }
  hideFavouriteModal(i, f) {
    $('#myFavoriteModal').modal('hide');
    console.log(window.location.href);
    // if(window.location.href.includes('/company?')){
    //   this._router.navigate(['company'], {
    //     queryParams: { id: i, isFavorite: f },
    //   });
    //   window.location.reload();
    // }
    // this._router.navigate(['company'], {
    //   queryParams: { id: i, isFavorite: f },
    // });
    // Construct the URL with the updated query parameters
    const url = `/company?id=${i}&isFavorite=${f}`;

    // Open the URL in a new browser window
    window.open(url, '_blank');
  }
  goToCompanies() {
    $('#myFavoriteModal').modal('hide');
    this._router.navigate(['companies'], {
      queryParams: { id: this._authService.getShopTypeId() },
    });
  }
  toggleFavorite(id, type) {
    console.log(id);
    this.RemoveElementFromArray(id, type);
  }
  RemoveElementFromArray(key: number, type) {
    if (type == 0) {
      this.favoriteService
        .addCompanyToFavorites(key, false)
        .subscribe((res) => {
          if (res.isSuccess)
            this.myFavoritesCompanies = this.myFavoritesCompanies.filter(
              (item) => item.id !== key
            );
        });
    } else {
      this.favoriteService
        .addProductToFavorites(key, false)
        .subscribe((res) => {
          if (res.isSuccess)
            this.myFavoritesProducts = this.myFavoritesProducts.filter(
              (item) => item.id !== key
            );
        });
    }

    // this.myFavoritesProducts.forEach((value, index) => {
    //   if (value.id == key) this.myFavoritesProducts.splice(index, 1);
    // });
    // console.log(this.objectArray);
  }
  getCountries() {
    this.lookupsService.getCountries().subscribe((res) => {
      console.log(this.countries);
      this.countries = res.data;
      if (!localStorage.getItem('countryId')) {
        localStorage.setItem('countryId', res.data[0].id);
        localStorage.setItem('flagImage', res.data[0].flagImage);
        localStorage.setItem('countryCode', res.data[0].countryCode);
      }
      if (!localStorage.getItem('currencySymbol')) {
        localStorage.setItem('currencyCode', res.data[0].currencyCode);
        localStorage.setItem('currencyName', res.data[0].currencyName);
        localStorage.setItem(
          'currencyPrecision',
          res.data[0].currencyPrecision
        );
        localStorage.setItem('currencySymbol', res.data[0].currencySymbol);
        localStorage.setItem('currencySymbolAr', res.data[0].currencySymbolAr);
        localStorage.setItem('currencySymbolEn', res.data[0].currencySymbolEn);
      }
    });
  }
  changeCountry(country) {
    $('.close').trigger('click');
    // let selectedIndex:number = event.target["selectedIndex"];
    // localStorage.setItem(
    //   'currencySymbol',
    //   countryCode == 'jo'
    //     ? 'JOD'
    //     : countryCode == 'kw'
    //     ? 'KWD'
    //     : countryCode == 'sa'
    //     ? 'SAR'
    //     : 'BBD'
    // );
    localStorage.setItem('currencyPrecision', country.currencyPrecision);
    localStorage.setItem('currencySymbol', country.currencySymbol);
    localStorage.setItem('countryName', country.name);
    localStorage.setItem('countryCode', country.countryCode);
    localStorage.setItem('flagImage', country.flagImage);
    // console.log(event.target.options[selectedIndex].getAttribute("data-name"))
    this.countryName = country.name;
    this.flagImage = country.flagImage;
    // console.log(event.target.value)
    this.GetCountryId.emit(country.id);
    console.log(this.autoComplete);
    this.options = {
      componentRestrictions: {
        country: country.countryCode,
      },
    };
    this.autoComplete.setOptions({
      componentRestrictions: { country: country.countryCode },
    });
    
    // this.options.componentRestrictions = { country: this.authService.getCountryCode() || 'jo' };
    this.searchAddress = ''; // Clear search input value when changing country
  }
  handlePlaceChanged(): void {
    const place = this.autoComplete.getPlace();
    if (place && place.formatted_address) {
      this.handleAddressChange(place);
    }
  }
  signOut() {
    // if (localStorage.getItem('provider')) this.authService.signOut();
    this._authService.logout();
    this._router.navigate(['login']);
  }
  validURL(str) {
    var pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i'
    ); // fragment locator
    return !!pattern.test(str); //!! return boolean
  }
  openmin(content: any) {
    this.modalService.open(content, {
      animation: true,
      size: 'xs',
      windowClass: 'modal-class',
    });
  }
  current_lang = localStorage.getItem('current_lang') || 'en';

  useLanguage(language: string) {
    // console.log(language)
    this.translate.use(language); //key
    this.settings.setLanguage(language);
  }
  changeLang(key) {
    let htmlTag = this.document.getElementsByTagName(
      'html'
    )[0] as HTMLHtmlElement;
    htmlTag.dir = key === 'ar' ? 'rtl' : 'ltr';
    htmlTag.lang = key;
    // console.log(event.target.value);
    this.translate.use(key);
    this.settings.setLanguage(key);
    this.settings.updateLang(key);
    localStorage.setItem('current_lang', key);
    if (key == 'ar') this.loadStyle('style-ar.css');
    else this.loadStyle('style.css');
    window.location.reload();
    this.settings.updateLang(key);
  }
  loadStyle(styleName: string) {
    const head = this.document.getElementsByTagName('head')[0];

    let themeLink = this.document.getElementById(
      'client-theme'
    ) as HTMLLinkElement;
    if (themeLink) {
      themeLink.href = `assets/css/${styleName}`;
    } else {
      const style = this.document.createElement('link');
      style.id = 'client-theme';
      style.rel = 'stylesheet';
      style.type = 'text/css';
      style.href = `assets/css/${styleName}`;
      head.appendChild(style);
    }
  }
}
