export class LoginModel {
  userNameOrEmail?: string;
  password?: string;
  countryId?: number;
  googleToken?: string;
  facebookToken?: string;
  rememberMe?: boolean;
  constructor() {
    this.userNameOrEmail = '';
    this.password = '';
    this.googleToken = '';
    this.facebookToken = '';
    this.rememberMe = false;
  }
}
export class LoginResponse {
  id: string;
  email: string;
  image: string;
  fullName: string;
  token: string;
  emailConfirmed: boolean;
}
// export class CompanyRegisterModel {
//   userName: string;
//   fullName: string;
//   email: string;
//   password: string;
//   confirmPassword: string;
//   countryId: number;
//   cityId: number;
//   shopTypeId: number;
//   companyName: string;
//   address: string;
//   phone: string;
//   latLng: string;
//   logo: string;
// }
export class CustomerRegisterModel {
  userName?: string;
  fullName?: string;
  email?: string;
  countryId?: number;
  password?: string;
  googleToken?: string;
  facebookToken?: string;
}
