import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CatalogService } from 'src/app/services/catalogs/catalog.service';
declare let $: any;

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class CategoriesComponent implements OnInit {
  currentCategory: string = 'All';
  current_lang = localStorage.getItem('current_lang') || 'en';

  @Input() id: number;
  @Output() GetIds = new EventEmitter();
  globalCategories: any[] = [];
  constructor(
    private catalogService: CatalogService,
    private activatedRoute: ActivatedRoute
  ) {}
  ids = new Array();
  ngOnInit(): void {
    this.id = Number(this.activatedRoute.snapshot.queryParamMap.get('id'));

    this.getGlobalCategories();
  }
  receiveDataFromParent() {
    // Handle the received data here
    this.ids = []
    // Remove 'category-style' class from anchor elements
    $('a').removeClass('category-style');

    // Remove 'cat-color' class from span elements
    $('span.cat-name').removeClass('cat-color');
  }
  toggleStyle(index: number, id) {
    let element = document.getElementById('cat' + index);
    let span = document.querySelector(`#cat${index} span`);
    if (!element.classList.contains('category-style')) {
      element.classList.add('category-style');
      span.classList.add('cat-color');
      this.ids.push(id);
    } else {
      element.classList.remove('category-style');
      span.classList.remove('cat-color');
      this.ids = this.ids.filter((element) => {
        return element !== id;
      });
    }
    this.GetIds.emit(this.ids);
  }

  getGlobalCategories() {
    this.catalogService.getAllGlobalCategories(this.id).subscribe((res) => {
      this.globalCategories = res.data;
    });
  }
  categories: any[] = [
    {
      label: 'All',
      icon: 'fa fa-star',
      category: 'All',
    },
    {
      label: 'CSS',
      icon: 'fa fa-star',
      category: 'CSS',
    },
    {
      label: 'JS',
      icon: 'fa-brands fa-js',
      category: 'JS',
    },
    {
      label: 'React',
      icon: 'fa-brands fa-react',
      category: 'React',
    },
    {
      label: 'Angular',
      icon: 'fa-brands fa-angular',
      category: 'Angular',
    },
    {
      label: 'Sass',
      icon: 'fa-brands fa-sass',
      category: 'Sass',
    },
    {
      label: 'NodeJS',
      icon: 'fa-brands fa-node-js',
      category: 'NodeJS',
    },
  ];
  scrollTo(element:HTMLElement,direction:number)
  {
    element.scrollBy({
      left: (direction*200),
      behavior: "smooth",
    });
  }
  
  select(el:HTMLElement)
  {
    this.currentCategory = el.getAttribute('data-item');

  }
}
