// import { MapsAPILoader } from '@agm/core';
import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CatalogService } from 'src/app/services/catalogs/catalog.service';
import {
  InterceptorSkipHeader,
  generateNumberArray,
} from 'src/app/shared/constants/global-variable.model';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  addressId = +localStorage.getItem('addressId');
  headers = new HttpHeaders().set(InterceptorSkipHeader, '');
  myAddresses: any[] = [];
  year = new Date().getFullYear();
  getMyAddresses() {
    if (this.authService.checkUserIsLogedIn())
      this._authService.getAddresses(this.headers).subscribe((res) => {
        this.myAddresses = res.data;
      });
  }
  map: google.maps.Map;
  geocoder: google.maps.Geocoder;
  searchAddress: string = '';
  marker: google.maps.Marker;
  currentLocationMarker: google.maps.Marker | null = null; // Initialize marker for current location

  getCurrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        // this.map.setCenter(pos);
        if (this.currentLocationMarker) {
          // If the marker for the current location exists, remove it from the map
          this.currentLocationMarker.setMap(null);
          this.currentLocationMarker = null; // Reset the currentLocationMarker reference
        }
        this.currentLocationMarker = new google.maps.Marker({
          position: pos,
          map: this.map,
          title: 'Your Location',
        });
        // new google.maps.Marker({
        //   position: pos,
        //   map: this.map,
        //   title: 'Your Location',
        // });
      });
    }
  }
  selectAddress(event) {
    // Get the selected value (address ID in this case)
    const selectedAddressId = +event.target.value;

    // Find the selected address object based on the ID
    const selectedAddress = this.myAddresses.find(
      (address) => address.id === selectedAddressId
    );
    console.log(selectedAddressId);
    console.log(selectedAddress);
    if (selectedAddress) {
      console.log(selectedAddress);
      localStorage.setItem('addressId', selectedAddress.id);
      this.lat = selectedAddress.latLng.split(',')[0];
      this.lng = selectedAddress.latLng.split(',')[1];
      localStorage.setItem('lng', this.lng.toString());
      localStorage.setItem('lat', this.lat.toString());
      this.address =
        selectedAddress.country +
        ' - ' +
        selectedAddress.city +
        ' - ' +
        selectedAddress.addressName;
    }
  }
  current_lang = localStorage.getItem('current_lang') || 'en';
  searchQuery: string;
  address = localStorage.getItem('address');
  lat = +localStorage.getItem('lat');
  lng = +localStorage.getItem('lng');
  public closeBanner: boolean | undefined;
  shopTypes: any[] = [];
  countries: any[] = [];
  countryId: number = 0;
  isApiLoaded = true;
  options: any = {
    // types: ['address'],
    // types: ['hospital', 'pharmacy', 'bakery', 'country', 'resturant', 'bank'],//Checkout https://developers.google.com/maps/documentation/javascript/supported_types
    componentRestrictions: {
      country:
        this.authService.getCountryCode() != 'undefined' &&
        this.authService.getCountryCode() == ''
          ? this.authService.getCountryCode()
          : 'jo',
    },
  };
  handleAddressChange(address: google.maps.places.PlaceResult) {
    this.address = address.formatted_address;
    this.lng = address.geometry.location.lng();
    this.lat = address.geometry.location.lat();
    console.log(address.formatted_address);
    localStorage.setItem('address', this.address);
    localStorage.setItem('lng', this.lng.toString());
    localStorage.setItem('lat', this.lat.toString());
  }
  saveAddress(id, nameEn) {
    localStorage.setItem('shopTypeId', id);
    localStorage.setItem('shopTypeName', nameEn);
    if (this.address) {
      localStorage.setItem('address', this.address);
      localStorage.setItem('lng', this.lng.toString());
      localStorage.setItem('lat', this.lat.toString());
    }
    if (localStorage.getItem('comId')) {
      console.log('comId');
      console.log(localStorage.getItem('comId'));
      if (this.address) {
        this.router
          .navigate(['/checkout'], {
            queryParams: { id: +localStorage.getItem('comId') },
          })
          .then(() => {
            localStorage.removeItem('comId');
          });
        return;
      }
    }
    this.router.navigate(['/companies'], { queryParams: { id: id } });
  }
  constructor(
    private catalogService: CatalogService,
    private router: Router,
    private authService: AuthService,
    private renderer: Renderer2,
    private http: HttpClient,
    private _authService: AuthService,
    @Inject(DOCUMENT) private document: Document,
    private elementRef: ElementRef
  ) {}
  toggleBanner() {
    this.closeBanner = !this.closeBanner;
  }
  @ViewChild('searchInput') searchInputRef!: ElementRef;
  updateInputValue(): void {
    const inputElement: HTMLInputElement = this.searchInputRef.nativeElement;
    inputElement.value = ''; // Clear input value
    setTimeout(() => {
      inputElement.dispatchEvent(new Event('input')); // Trigger input event
    });
  }
  autoComplete: google.maps.places.Autocomplete;
  getCountryId(id) {
    // console.log(this.authService.getCountryCode())
    this.autoComplete.setOptions({
      componentRestrictions: { country: this.authService.getCountryCode() },
    });
    // this.options.componentRestrictions = { country: this.authService.getCountryCode() || 'jo' };
    this.searchAddress = ''; // Clear search input value when changing country
    // this.updateInputValue(); // Update input field value

    this.countryId = +id;
    localStorage.setItem('countryId', id);
    this.getShopTypes();
  }
  loadScript() {
    return new Promise((resolve, reject) => {
      // const allScripts = document.getElementsByTagName('script');
      // for (let i = 0; i < allScripts.length; i++) {
      //   if (
      //     allScripts[i].src.indexOf(
      //       'key=AIzaSyBnKj_Q9Uu4rzrB_bIxhvqJPfbn8g6b3m4'
      //     ) > 0
      //   ) {
      //     this.renderer.removeChild(document.body, allScripts[i]);
      //   }
      // }
      // [].filter
      //   .call(
      //     allScripts,
      //     (scpt) => scpt.src.indexOf('key=AIzaSyBnKj_Q9Uu4rzrB_bIxhvqJPfbn8g6b3m4') >= 0
      //   )[0]
      //   .remove();
      // const element = this.document.createElement('script');
      // element.type = 'text/javascript';
      // element.src =
      //   'https://maps.googleapis.com/maps/api/js?key=AIzaSyBnKj_Q9Uu4rzrB_bIxhvqJPfbn8g6b3m4&libraries=places&language=en'; //<script type="text/javascript" src="https://maps.googleapis.com/maps/api/js?key=YOUR_KEY_HERE&libraries=places&sensor=false"></script>
      // element.onload = resolve;
      // element.onerror = reject;
      // this.elementRef.nativeElement.appendChild(element);
    });
  }
  handlePlaceChanged(): void {
    const place = this.autoComplete.getPlace();
    if (place && place.formatted_address) {
      this.handleAddressChange(place);
    }
  }
  ngOnInit(): void {
    console.log(this.authService.getCountryCode());
    // this.options.componentRestrictions.country =
    //   this.authService.getCountryCode() || 'jo';
    setTimeout(() => {
      this.autoComplete = new google.maps.places.Autocomplete(
        this.searchInputRef.nativeElement,
        {
          componentRestrictions: {
            country: this.authService.getCountryCode() || 'jo',
          }, // Default country
        }
      );
      this.autoComplete.addListener('place_changed', this.handlePlaceChanged.bind(this));

    }, 1000);
    // this.loadScript().then(() => {
    //   this.isApiLoaded = true;
    // });
    this.getShopTypes();
    this.getCurrentLocation();
  }
  // ngAfterViewInit(){
  //   console.log(this.options)
  // }
  arrayOfShopTypes = [];
  getShopTypes() {
    this.getMyAddresses();
    this.countryId = +localStorage.getItem('countryId') || 1;
    this.catalogService.getAllShopTypes(+this.countryId).subscribe((res) => {
      this.shopTypes = res.data;
      this.arrayOfShopTypes = generateNumberArray(0, res.data.length - 1);
    });
  }
  validURL(str) {
    var pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i'
    ); // fragment locator
    return !!pattern.test(str); //!! return boolean
  }
  onInputChange() {
    if (this.searchQuery) {
      const apiKey = 'AIzaSyBnKj_Q9Uu4rzrB_bIxhvqJPfbn8g6b3m4';
      const apiUrl = `https://maps.googleapis.com/maps/api/place/textsearch/json?query=${this.searchQuery}&key=${apiKey}`;

      this.http.get(apiUrl).subscribe((response: any) => {
        if (response && response.results) {
          const addresses = response.results.map(
            (result) => result.formatted_address
          );
          console.log(addresses);
        }
      });
    }
  }
}
