import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Filter, ResponseResult } from 'src/app/models-classes/common.model';
import { InterceptorSkipHeader } from 'src/app/shared/constants/global-variable.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CatalogService {
  api_url = environment.api.baseUrl;
  headers = new HttpHeaders().set(InterceptorSkipHeader, '');

  // private _params: Filter = new PaginationFilter();

  // get params(): Filter {
  //   return this._params;
  // }
  // set params(params) {
  //   this._params = params;
  // }
  // action(value: string): string {
  //   return value + Object.keys(this.params).map(key => key + '=' + this.params[key]).join('&');
  // }
  constructor(private _http: HttpClient) {
  }
  getAllShopTypes(countryId: number): Observable<ResponseResult> {
    return this._http.get<ResponseResult>(this.api_url + '/Catalog/ShopTypes?countryId=' + countryId, {});
  }
  getAllGlobalCategories(shopTypeId: number): Observable<ResponseResult> {
    return this._http.get<ResponseResult>(this.api_url + '/Catalog/GlobalCategories?shopTypeId=' + shopTypeId, { headers: this.headers });
  }
  companiesList(model: Filter,countryId): Observable<ResponseResult> {
    return this._http.post<ResponseResult>(this.api_url + `/Catalog/CompaniesList?countryId=${countryId}`, model);
  }
  productList(model: Filter): Observable<ResponseResult> {
    return this._http.post<ResponseResult>(this.api_url + '/Catalog/ProductsList', model);
  }
  getCompaniesCategoriesAndProducts(companyId: number): Observable<ResponseResult> {
    return this._http.get<ResponseResult>(this.api_url + `/Catalog/CompanyCategoriesAndProducts?companyId=${companyId}`, {});
  }
  getCompanyFullDetails(companyId: number): Observable<ResponseResult> {
    return this._http.get<ResponseResult>(this.api_url + `/Catalog/CompanyFullDetails?companyId=${companyId}`, { headers: this.headers });
  }
}
