import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Order } from 'src/app/models-classes/order/order.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css'],
})
export class PaymentComponent implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private translateService: TranslateService,
    private router: Router,
    private _toast: ToastrService,
    private settingService: SettingsService
  ) {}
  orderId = 0;
  // @ViewChild('iframeElement') iframeElement: ElementRef;
  // constructor(private renderer: Renderer2) {}
  // paymentFormHTML = localStorage.getItem('paymentFormHTML')
  encrp = '';
  error = '';
  check = 0;
  payment() {
    if (this.encrp != 'null' || this.error != 'null') {
      let enc = this.encrp == 'null';
      let err = this.error == 'null';
      this.authService
        .payment(enc ? '' : this.encrp, err ? '' : this.error)
        .subscribe((res) => {
          if (res.isSuccess && !enc) {
            const storedOrderJson = localStorage.getItem('order');

            // Parse the JSON string back to a JavaScript object
            const storedOrderData = JSON.parse(storedOrderJson);
            // Create an instance of the Order class
            const storedOrder = new Order();

            // Assign the properties from the parsed JSON object to the Order instance
            Object.assign(storedOrder, storedOrderData);
            storedOrder.paymentRefNo = res.data.paymentRefNo;
            console.log(res.data);
            this.authService.createOrder(storedOrder).subscribe((res) => {
              if (res.isSuccess) {
                let companyId = localStorage.getItem('companyId');
                localStorage.removeItem('order');
                this.settingService.deleteOrdersWithCompanyId(
                  'my_orders',
                  +companyId
                );
                localStorage.removeItem('companyId');
                this.check = 1;
                this.orderId = res.data;
              } else {
                this.check = 2;
                const translatedErrors = res.errors.map((error) =>
                  this.translateService.instant(error)
                );
                const errorMessage = translatedErrors.join('\n');
                this._toast.error(errorMessage);
                // let errorMessage = res.errors.join('\n'); // Using newline for better readability
                // this._toast.error(errorMessage);
              }
            });
          } else {
            this.check = 2;
            const translatedErrors = res.errors.map((error) =>
              this.translateService.instant(error)
            );
            const errorMessage = translatedErrors.join('\n');
            this._toast.error(errorMessage);
            // let errorMessage = res.errors.join('\n'); // Using newline for better readability
            // this._toast.error(errorMessage);
          }
        });
    } else this.router.navigate(['/']);
  }
  ngOnInit() {
    this.encrp = String(
      this.activatedRoute.snapshot.queryParamMap.get('encrp')
    );
    this.error = String(
      this.activatedRoute.snapshot.queryParamMap.get('ErrorCode')
    );
    console.log(this.encrp == null);
    console.log(this.encrp);
    console.log(this.encrp == 'null');
    this.payment();
    // After the component has been initialized, add a listener for the load event of the iframe
    // this.renderer.listen(this.iframeElement.nativeElement, 'load', () => {
    //   // Do something after the iframe has loaded (e.g., show a message)
    //   this.paymentFormHTML = localStorage.getItem('paymentFormHTML');
    //   console.log('Iframe loaded');
    // });
  }
}
