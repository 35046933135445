<!-- <section class="browse-cat-100 u-line section-padding2 header">
  <div class="container">
    <div class="row">
      <div *ngIf="globalCategories.length == 0" class="col-12">
        <div class="item">
          <ngx-skeleton-loader count="1" animation="progress" [theme]="{
            'border-radius': '5px',
            height: '40px',
            border: '1px solid white'
          }"></ngx-skeleton-loader>
        </div>
      </div>
      <div class="col-12">
        <div class="categories-swiper-container swiper-container">
          <div class="swiper-wrapper swipper-width-auto">
              <ng-container *ngFor="let item of globalCategories; let i=index">
                <div class="swiper-slide">
                  <a [id]="'cat' + i" (click)="toggleStyle(i,item.id)" href="javascript:void(0)" class="categories p-2">
                    <span class="text-light-black2 cat-name">{{item.nameEn}}</span>
                  </a>
                </div>
              </ng-container>
          </div>
          <div class="swiper-button-next swiper-button-next2"></div>
          <div class="swiper-button-prev swiper-button-prev2"></div>
        </div>
      </div>
    </div>
  </div>
</section> -->


<ng-container *ngIf="globalCategories.length == 0" class="swiper-slide">
  <ngx-skeleton-loader count="1" animation="progress" [theme]="{
      'border-radius': '5px',
      height: '40px',
      border: '1px solid white'
    }"></ngx-skeleton-loader>
</ng-container>

<div class="popular-item-slider instagram-slider">
  <div class="swiper-wrapper">
    <div class="swiper-slide" *ngFor="let item of globalCategories; let i=index">
      <a class="categories" [id]="'cat' + i" (click)="toggleStyle(i,item.id)" href="javascript:void(0)">
        <div class="icon text-custom-white">
          <img src="assets/img/restaurants/125x125/cuisine-11.jpg" class="rounded-circle" alt="categories">
        </div>
        <span class="text-light-black cat-name">{{ current_lang == 'en' ? item.nameEn : item.nameAr }}</span>
      </a>
    </div>
  </div>
  <!-- Add Arrows -->
  <div class="swiper-button-next"></div>
  <div class="swiper-button-prev"></div>
</div>