<div class="inner-wrapper">
  <div class="container-fluid no-padding">
    <div class="row no-gutters overflow-auto">
      <div class="col-md-6">
        <div class="main-banner">
          <img src="assets/img/banner/banner-1.jpg" class="img-fluid full-width main-img" alt="banner">
          <div class="overlay-2 main-padding">
            <img src="assets/img/logo-2.jpg" class="img-fluid" alt="logo">
          </div>
          <img src="assets/img/banner/burger.png" class="footer-img" alt="footer-img">
        </div>
      </div>
      <div class="col-md-6">
        <div class="section-2 user-page main-padding">
          <div class="login-sec">
            <div class="login-box">
              <form *ngIf="show_email_feild" [formGroup]="resetForm"
                (ngSubmit)="onSubmit(resetForm.value,formDirective)" #formDirective="ngForm">
                <h4 class="text-light-black fw-600">{{'Reset Your Password' | translate}}</h4>
                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <label class="text-light-white fs-14">{{'Email' | translate}}</label>
                      <input
                        [ngClass]="{ 'is-invalid': f.email.errors && (f.email.dirty || submitted), 'is-valid': f.email.valid }"
                        type="text" formControlName="email" class="form-control form-control-submit"
                        placeholder="Email I'd">
                      <div *ngIf="f.email.touched && f.email.errors" class="invalid-feedback">
                        <div *ngIf="f.email.errors.required">{{'Full Name' | translate}} {{'validations.isrequired' |
                          translate}}</div>
                        <div *ngIf="f.email.errors.email">{{'validations.invalid_email' | translate}}</div>
                      </div>
                    </div>
                    <!-- <div class="form-group">
                      <label class="text-light-white fs-14">Password</label>
                      <input formControlName="password" type="password" id="password-field" name="#" class="form-control form-control-submit" value="" placeholder="Password">
                      <div data-name="#password-field" class="fa fa-fw fa-eye field-icon toggle-password"></div>
                    </div> -->
                    <div class="form-group">
                      <button type="submit" class="btn-second btn-submit full-width">
                        {{'Reset Your Password' | translate}}</button>
                    </div>
                    <div class="form-group text-center"> <span>{{'or' | translate}}</span>
                    </div>
                    <!-- <div *ngIf="email_confirm" class="form-group text-center mb-1"> <a (click)="open(modaladd)" style="color:#ff0018;font-weight: bold;">Resend Confirmation Code</a>
                    </div> -->
                    <div class="form-group text-center mb-0"> <a routerLink="/login">{{'Login' | translate}}</a>
                    </div>
                  </div>
                </div>
              </form>
              <form *ngIf="!show_email_feild" [formGroup]="resetConfirmForm"
                (ngSubmit)="confirmReset(resetConfirmForm.value,formDirective)" #formDirective="ngForm">
                <h4 class="text-light-black fw-600">{{'Reset Your Password' | translate}}</h4>
                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <label class="text-light-white fs-14">{{'Code' | translate}}</label>
                      <input
                        [ngClass]="{ 'is-invalid': f1.code.errors && (f1.code.dirty || submitted), 'is-valid': f1.code.valid }"
                        formControlName="code" type="text" class="form-control form-control-submit" value=""
                        placeholder="Code">
                      <div *ngIf="f1.code.touched && f1.code.errors" class="invalid-feedback">
                        <div *ngIf="f1.code.errors.required">{{'Code' | translate}} {{'validations.isrequired' |
                          translate}}</div>
                      </div>
                    </div>
                    <div class="form-group">
                      <!-- <label class="text-light-white fs-14">Password (8 character minimum)</label>
                      <input
                        [ngClass]="{ 'is-invalid': f1.password.errors && (f1.password.dirty || submitted), 'is-valid': f1.password.valid }"
                        type="password" id="password-field" formControlName="password" name="#"
                        class="form-control form-control-submit pass" value="password" placeholder="Password" required>
                      <div data-name="#password-field" class="fa fa-fw fa-eye field-icon toggle-password"></div> -->
                      <label class="text-light-white fs-14">{{'Password' | translate}}</label>
                      <input required formControlName="password" type="password" id="password-field" name="#"
                        class="form-control form-control-submit pass" value=""
                        [ngClass]="{ 'is-invalid': f1.password.errors && (f1.password.dirty || submitted), 'is-valid': f1.password.valid }">
                      <div data-name="#password-field" class="fa fa-fw fa-eye field-icon toggle-password"></div>
                      <div *ngIf="f1.password.touched && f1.password.errors" class="invalid-feedback">
                        <!-- required -->
                        <span class="text-danger"
                          *ngIf="(f1.password.dirty || submitted) && !requiredValid">{{'Password' | translate}}
                          {{'validations.isrequired' | translate}}</span>

                        <!-- password validation rules -->
                        <div id="validation-rules" *ngIf="requiredValid">
                          <!-- minlength -->
                          <div [ngClass]="{ 'text-success': minLengthValid, 'text-danger': !minLengthValid }">
                            <i [attr.class]="minLengthValid ? 'fa fa-check-square' : 'fa fa-window-close'"></i>
                            {{'validations.minlength' | translate : {number:8} }}
                          </div>

                          <!-- requires digit -->
                          <div [ngClass]="{ 'text-success': requiresDigitValid, 'text-danger': !requiresDigitValid }">
                            <i [attr.class]="requiresDigitValid ? 'fa fa-check-square' : 'fa fa-window-close'"></i>
                            {{'validations.requiresDigit' | translate : {number:1} }}
                          </div>

                          <!-- requires uppercase -->
                          <div
                            [ngClass]="{ 'text-success': requiresUppercaseValid, 'text-danger': !requiresUppercaseValid }">
                            <i [attr.class]="requiresUppercaseValid ? 'fa fa-check-square' : 'fa fa-window-close'"></i>
                            {{'validations.requiresUppercase' | translate:{number:1} }}
                          </div>

                          <!-- requires lowercase -->
                          <div
                            [ngClass]="{ 'text-success': requiresLowercaseValid, 'text-danger': !requiresLowercaseValid }">
                            <i [attr.class]="requiresLowercaseValid ? 'fa fa-check-square' : 'fa fa-window-close'"></i>
                            {{'validations.requiresLowercase' | translate : {number:1} }}
                          </div>

                          <!-- requires special characters -->
                          <div
                            [ngClass]="{ 'text-success': requiresSpecialCharsValid, 'text-danger': !requiresSpecialCharsValid }">
                            <i
                              [attr.class]="requiresSpecialCharsValid ? 'fa fa-check-square' : 'fa fa-window-close'"></i>
                            {{'validations.requiresSpecialChars' | translate : {number:1} }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="form-group">
                      <label class="text-light-white fs-14">Password</label>
                      <input formControlName="password" type="password" id="password-field" name="#" class="form-control form-control-submit" value="" placeholder="Password">
                      <div data-name="#password-field" class="fa fa-fw fa-eye field-icon toggle-password"></div>
                    </div> -->
                    <div class="form-group">
                      <button type="submit" class="btn-second btn-submit full-width">
                        {{'Reset Your Password' | translate}}</button>
                    </div>
                    <div class="form-group text-center"> <span>or</span>
                    </div>
                    <!-- <div *ngIf="email_confirm" class="form-group text-center mb-1"> <a (click)="open(modaladd)" style="color:#ff0018;font-weight: bold;">Resend Confirmation Code</a>
                    </div> -->
                    <div class="form-group text-center mb-0"> <a routerLink="/login">{{'Login' | translate}}</a>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>