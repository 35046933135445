import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { CustomerRegisterModel } from 'src/app/models-classes/auth/login-model.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SettingsService } from 'src/app/services/settings.service';
import { CustomValidators } from 'src/app/shared/validators/custom-validators';
declare let $: any;

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
})
export class RegisterComponent implements OnInit {
  langs = {
    en: 'English',
    ar: 'Arabic',
  };
  flagImage =
    localStorage.getItem('flagImage') ||
    'https://rahaldeliverystorage.blob.core.windows.net/static/jordan.png';
  current_lang = localStorage.getItem('current_lang') || 'en';
  confirm_email = false;
  constructor(
    private _authService: AuthService,
    private _toast: ToastrService,
    private _router: Router,
    private modalService: NgbModal,
    private fb: UntypedFormBuilder,
    public translate: TranslateService,
    @Inject(DOCUMENT) private document: Document,
    public settings: SettingsService
  ) {}
  submitted = false;
  submitted1 = false;
  registerForm: UntypedFormGroup;
  confirmEmailForm: UntypedFormGroup;
  type = 0;
  get f() {
    return this.registerForm.controls;
  }
  get f1() {
    return this.confirmEmailForm.controls;
  }

  get passwordValid() {
    return this.registerForm.controls['password'].errors === null;
  }

  get requiredValid() {
    return !this.registerForm.controls['password'].hasError('required');
  }

  get minLengthValid() {
    return !this.registerForm.controls['password'].hasError('minlength');
  }

  get requiresDigitValid() {
    return !this.registerForm.controls['password'].hasError('requiresDigit');
  }

  get requiresUppercaseValid() {
    return !this.registerForm.controls['password'].hasError(
      'requiresUppercase'
    );
  }

  get requiresLowercaseValid() {
    return !this.registerForm.controls['password'].hasError(
      'requiresLowercase'
    );
  }

  get requiresSpecialCharsValid() {
    return !this.registerForm.controls['password'].hasError(
      'requiresSpecialChars'
    );
  }
  setConfirmEmailForm() {
    this.confirmEmailForm = new UntypedFormGroup({
      code: new UntypedFormControl('', [Validators.required]),
      email: new UntypedFormControl('', [Validators.required]),
    });
  }
  ngOnInit(): void {
    this.registerForm = this.fb.group({
      userName: ['', [Validators.required]],
      fullName: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      password: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(8),
          CustomValidators.patternValidator(new RegExp('(?=.*[0-9])'), {
            requiresDigit: true,
          }),
          CustomValidators.patternValidator(new RegExp('(?=.*[A-Z])'), {
            requiresUppercase: true,
          }),
          CustomValidators.patternValidator(new RegExp('(?=.*[a-z])'), {
            requiresLowercase: true,
          }),
          CustomValidators.patternValidator(new RegExp('(?=.*[$@^!%*?&])'), {
            requiresSpecialChars: true,
          }),
        ]),
      ],
    });
  }
  changeType(type) {
    this.type = type;
  }
  resendEmail() {
    if (this.confirmEmailForm.value.email) {
      this._authService
        .resendOtp(this.confirmEmailForm.value.email)
        .subscribe((res) => {
          if (res.isSuccess) {
            this._toast.success(
              this.translate.instant(
                'The activation code has been sent again. Please check your email'
              )
            );
          } else
            this._toast.error(this.translate.instant('Some thing went wrong'));
        });
    }
  }
  open(content: any) {
    this.modalService.open(content, {
      centered: true,
      windowClass: 'modal-max',
    });
  }
  confirmMail(data, formDirective: FormGroupDirective) {
    this.submitted1 = true;
    if (!this.confirmEmailForm.valid) {
      return;
    }
    this._authService.confirmEmail(data.email, data.code).subscribe((res) => {
      if (res.isSuccess) {
        this._toast.success(this.translate.instant('ConfirmAccount'));
        this.confirmEmailForm.reset();
        formDirective.resetForm();
        this.modalService.dismissAll();
        this.confirm_email = false;
        this._router.navigate(['login'])
      } else{
        const translatedErrors = res.errors.map((error) =>
          this.translate.instant(error)
        );
        const errorMessage = translatedErrors.join('\n');
        this._toast.error(errorMessage);
      }
        // this._toast.error(
        //   this.translate.instant('Some thing went wrong'),
        //   this.translate.instant('Email Confirmation'),
        //   {
        //     positionClass: 'toast-top-left',
        //     progressBar: true,
        //   }
        // );
    });
  }
  @ViewChild("modaladd",{static:true}) content:ElementRef;

  onSubmit(data, formDirective: FormGroupDirective) {
    this.submitted = true;
    let registerModel: CustomerRegisterModel = new CustomerRegisterModel();
    if (this.type == 0) {
      if (this.registerForm.invalid) {
        return;
      }
      registerModel = {
        password: data.password,
        countryId: +localStorage.getItem('countryId') || 0,
        email: data.email,
        userName: data.userName,
        fullName: data.fullName,
      };
    } else if (this.type == 1) {
      registerModel = {
        facebookToken: '1',
      };
    } else {
      registerModel = {
        googleToken: '2',
      };
    }
    this._authService.customerRegister(registerModel).subscribe((res) => {
      // let result = JSON.parse(res.data);
      // console.log(res.data.emailConfirmed);
      if (res.isSuccess) {
        if (this.type == 0) {
          this.confirm_email = true;
          this.setConfirmEmailForm();
          this.confirmEmailForm.patchValue({
            email: data.email,
          });
        }
        this.confirm_email = true;
        this._toast.success(this.translate.instant('ConfirmEmail'));
        this.registerForm.reset();
        CustomValidators.ResetCustomValidators(this.registerForm);
        this.modalService.open(this.content);
        // this._router.navigate(['/']);
      } else {
        const translatedErrors = res.errors.map((error) =>
          this.translate.instant(error)
        );
        const errorMessage = translatedErrors.join('\n');
        this._toast.error(errorMessage);
      }
    });
  }
  changeLang(key) {
    let htmlTag = this.document.getElementsByTagName(
      'html'
    )[0] as HTMLHtmlElement;
    htmlTag.dir = key === 'ar' ? 'rtl' : 'ltr';
    htmlTag.lang = key;
    // console.log(event.target.value);
    this.translate.use(key);
    this.settings.setLanguage(key);
    this.settings.updateLang(key);
    localStorage.setItem('current_lang', key);
    if (key == 'ar') this.loadStyle('style-ar.css');
    else this.loadStyle('style.css');
    window.location.reload();
    this.settings.updateLang(key);
  }
  loadStyle(styleName: string) {
    const head = this.document.getElementsByTagName('head')[0];

    let themeLink = this.document.getElementById(
      'client-theme'
    ) as HTMLLinkElement;
    if (themeLink) {
      themeLink.href = `assets/css/${styleName}`;
    } else {
      const style = this.document.createElement('link');
      style.id = 'client-theme';
      style.rel = 'stylesheet';
      style.type = 'text/css';
      style.href = `assets/css/${styleName}`;
      head.appendChild(style);
    }
  }
}
