<app-navbar [address]="address" (GetCountryId)="getCountryId($event)"></app-navbar>
<!-- <div id="parent">
  <section  class="home-swipper-container swiper-container p-relative swiper-container swiper-container-initialized swiper-container-horizontal">
    <div class="row" *ngIf="shopTypes.length == 0">
      <div class="col-12">
        <div class="slide-item">
          <ngx-skeleton-loader count="1" animation="progress"></ngx-skeleton-loader>
        </div>
      </div>
    </div>
    <div class="swiper-wrapper">
      <div class="swiper-slide slide-item" *ngFor="let item of shopTypes" data-swiper-autoplay="50000">
        <div class="row no-gutters">
          <div class="col-md-6">
            <div class="main-banner">
              <img [src]="validURL(item.image) ? item.image : 'assets/img/banner/banner-2.jpg'"
                class="img-fluid full-width main-img" alt="banner">
            </div>
          </div>
          <div class="col-md-6">
            <div class="section-2 main-page main-padding">
              <div class="login-box">
                <div class="col-12 margin-top">
                  <h1 class="text-light-black fw-700">{{item.nameEn}}</h1>
                  <span class="text-light-black fw-700">{{item.descriptionEn}}</span>
                  <div class="input-group row">
                    <div class="input-group2 col-xl-8" *ngIf="isApiLoaded">
                      <input type="search" class="form-control form-control-submit" ngx-google-places-autocomplete
                        [options]="options" (onAddressChange)="handleAddressChange($event)" />
                    </div>
                    <div class="input-group-append col-xl-4">
                      <a (click)="saveAddress(item.id,item.nameEn)" class="btn-second btn-submit full-width text-white"
                        type="button">{{'Continue' | translate}}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="swiper-button-next"></div>
    <div class="swiper-button-prev"></div>
  </section>
  <app-footer2></app-footer2>
</div> -->
<section class="banner-1 p-relative">
  <img src="/assets/img/banner-3.jpg" class="img-fluid full-width" alt="Banner">
  <div class="transform-center">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="content-wrapper bg-white padding-20">
            <div class="content-box padding-tb-10">
              <h2 class="text-light-black fw-700">{{'Order food and more' | translate}}</h2>
              <p class="text-light-black fw-700">{{'Find a location near you' | translate}}</p>
              <div class="input-group row">
                <div class="input-group2 col-xl-8">
                  <input #searchInput type="search" class="form-control form-control-submit" ngx-google-places-autocomplete
                    [options]="options" (onAddressChange)="handleAddressChange($event)" [(ngModel)]="searchAddress"
                    placeholder="{{'Enter street address or zip code' | translate}}" value="1246 57th St, Brooklyn, NY, 11219">
                  <div class="input-group-prepend">
                    <button class="input-group-text text-light-green"><i class="fab fa-telegram-plane"></i>
                    </button>
                  </div>
                </div>
                <select *ngIf="myAddresses?.length > 0" (change)="selectAddress($event)" class="full-width col-xl-4">
                  <option value="">{{'My Address' | translate}}</option>
                  <option [selected]="addressId == address.id" *ngFor="let address of myAddresses" [value]="address.id">
                    {{address.country}} - {{address.city}} - {{address.addressName}}</option>
                </select>
                <!-- <div class="input-group-append col-xl-4">
                                  <button class="btn-second btn-submit full-width" type="button">Find</button>
                              </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="overlay overlay-bg"></div>
</section>

<section class="ex-collection section-padding">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="section-header-left">
          <h3 class="text-light-black header-title title">{{'Explore our collections' | translate}}</h3>
        </div>
      </div>
    </div>
    <div class="row">
      <ng-container *ngIf="shopTypes?.length == 0">
        <div class="col-md-6" *ngFor="let _ of [1,2]">
          <ng-container>
            <ngx-skeleton-loader animation="progress" ></ngx-skeleton-loader>
          </ng-container>
          <!-- <div class="ex-collection-box mb-xl-20"> -->
            <!-- Add your skeleton loading UI here -->
            <!-- <ng-container>
              <ejs-skeleton cssClass="e-customize" id="cardImage" shape="Rectangle" width="100%"
                height='150px'></ejs-skeleton>
            </ng-container> -->
          <!-- </div> -->
        </div>
      </ng-container>
      <ng-container *ngIf="shopTypes?.length != 0">
        <div class="col-md-6" *ngFor="let item of shopTypes">
          <div class="ex-collection-box mb-xl-20">
            <img [src]="validURL(item.image) ? item.image : 'assets/img/banner/banner-2.jpg'"
              class="full-width equal-height-image" alt="image">
            <div class="category-type overlay padding-15"> <a (click)="saveAddress(item.id,item.nameEn)"
                class="category-btn">{{current_lang == 'en' ? item.nameEn : item.nameAr}}</a>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</section>
<div class="footer-top section-padding-40">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-3 col-sm-4 col-6 mb-sm-20">
        <div *ngIf="current_lang == 'en'" class="icon-box"> <span class="text-light-green"><i
              class="flaticon-credit-card-1"></i></span>
          <span>{{'100% Payment' | translate}}<br>{{'Secured' | translate}}</span>
        </div>
        <div *ngIf="current_lang == 'ar'" class="icon-box"> <span class="text-light-green"><i
              class="flaticon-credit-card-1"></i></span>
          <span>{{'securepayment' | translate}}<br>100%</span>
        </div>
      </div>
      <div class="col-md-3 col-sm-4 col-6 mb-sm-20">
        <div class="icon-box"> <span class="text-light-green"><i class="flaticon-wallet-1"></i></span>
          <span>{{'Support lots' | translate}}<br> {{'of Payments' | translate}}</span>
        </div>
      </div>
      <!-- <div class="col-md-2 col-sm-4 col-6 mb-sm-20">
        <div class="icon-box"> <span class="text-light-green"><i class="flaticon-help"></i></span>
          <span>24 hours / 7 days<br>Support</span>
        </div>
      </div> -->
      <!-- <div class="col-md-2 col-sm-4 col-6">
        <div class="icon-box"> <span class="text-light-green"><i class="flaticon-truck"></i></span>
          <span>Free Delivery<br>with $50</span>
        </div>
      </div> -->
      <div class="col-md-3 col-sm-4 col-6">
        <div class="icon-box"> <span class="text-light-green"><i class="flaticon-guarantee"></i></span>
          <span>{{'Best Price' | translate}}<br>{{'Guaranteed' | translate}}</span>
        </div>
      </div>
      <div class="col-md-3 col-sm-4 col-6">
        <div class="icon-box"> <span class="text-light-green"><i class="flaticon-app-file-symbol"></i></span>
          <span>{{'Mobile Apps' | translate}}<br>{{'Ready' | translate}}</span>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer2></app-footer2>