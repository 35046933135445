import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Order } from 'src/app/models-classes/order/order.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-card-payment',
  templateUrl: './card-payment.component.html',
  styleUrls: ['./card-payment.component.css']
})
export class CardPaymentComponent implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private translateService:TranslateService,
    private router: Router,
    private _toast: ToastrService,
    private settingService:SettingsService
  ) {}
  // @ViewChild('iframeElement') iframeElement: ElementRef;
  // constructor(private renderer: Renderer2) {}
  // paymentFormHTML = localStorage.getItem('paymentFormHTML')
  resultIndicator = '';
  orderId = 0;
  sessionVersion = '';
  checkoutVersion = '';
  check = 0;
  orders: any[] = [];
  payment() {
    const storedOrderJson = localStorage.getItem('order');
    if (this.resultIndicator != 'null') {
      let ind = this.resultIndicator == 'null';
      // Parse the JSON string back to a JavaScript object
      const storedOrderData = JSON.parse(storedOrderJson);
      // Create an instance of the Order class
      const storedOrder = new Order();

      // Assign the properties from the parsed JSON object to the Order instance
      Object.assign(storedOrder, storedOrderData);
      storedOrder.paymentSuccessToken = this.resultIndicator;
      // let sessionVer = this.sessionVersion == 'null';
      // let checkoutVer = this.checkoutVersion == 'null';
      // console.log(storedOrder);
      this.authService
        .createOrder(storedOrder)
        .subscribe((res) => {
          if (res.isSuccess) {
          let companyId = localStorage.getItem('companyId');
          localStorage.removeItem('order')
          this.settingService.deleteOrdersWithCompanyId(
            'my_orders',
            +companyId
            );
            localStorage.removeItem('companyId')
            this.check = 1;
            this.orderId = res.data
            localStorage.setItem('newAddress','false');
          } else {
            this.check = 2;
            const translatedErrors = res.errors.map((error) =>
            this.translateService.instant(error)
          );
          const errorMessage = translatedErrors.join('\n');
          this._toast.error(errorMessage);

            // let errorMessage = res.errors.join('\n'); // Using newline for better readability
            // this._toast.error(errorMessage);
          }
        });
    } else this.router.navigate(['/']);
  }
  ngOnInit() {
    this.resultIndicator = String(
      this.activatedRoute.snapshot.queryParamMap.get('resultIndicator')
    );
    this.sessionVersion = String(
      this.activatedRoute.snapshot.queryParamMap.get('sessionVersion')
    );
    this.checkoutVersion = String(
      this.activatedRoute.snapshot.queryParamMap.get('checkoutVersion')
    );
    this.payment();
  }
}
