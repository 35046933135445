<app-navbar (GetCountryId)="getCountryId($event)"></app-navbar>
<div class="row position-fixed vw-100 vh-100">
  <div id="content" (scroll)="onScroll($event)" class="col-lg-9 p-0 m-0 d-flex flex-column vh-100"
    style="overflow-y: scroll">
    <div id="header">
      <img class="w-100 max-height-image" [src]="
          !company?.coverImage
            ? 'assets/img/video-banner.jpg'
            : company?.coverImage
        " alt="..." />
      <div class="logo-avatar-parent">
        <img class="logo-avatar" [src]="
        !company?.logo
          ? 'assets/img/video-banner.jpg'
          : company?.logo" alt="..." />
      </div>
      <div class="container" id="headerContent">
        <h1 class="d-flex justify-content-between">
          <div class="d-flex flex-column text-style-right">
            <span class="font-24">{{ company?.name }} </span>
            <p>{{ company?.description }}</p>
          </div>
          <div class="d-flex align-self-start">
            <div class="icon-back-color" (click)="open(companyInfo,0)">
              <a href="javascript:void(0)" class="fa fa-info-circle icon-font"></a>
            </div>
            <div (click)="toggleFavorite()" *ngIf="authService.checkUserIsLogedIn()">
              <a class="pl-1 icon-font" href="javascript:void(0)">
                <i [class.active-color]="fav" class="fa fa-heart"></i>
              </a>
            </div>
          </div>
        </h1>
        <div>
          <i class="fas fa-star main-color" *ngFor="let item of getarr(company?.rate)"></i>
          <i class="fa fa-star" *ngFor="let item of getarr(5 - company?.rate)"></i>
          <span> ({{ company?.reviewsCount }} {{'reviews' | translate}})</span>
        </div>
        <div class="d-flex gap-3">
          <div class="">
            <span [ngClass]="current_lang == 'en' ? 'pr-1' : 'pl-1'" class="icon-color fa fa-clock"></span>
            <span class="icon-color">{{'Delivery in' | translate}}</span>
            <span class="icon-color">
              {{ company?.deliveryTime }}{{'Minute' | translate}}</span>
          </div>
          <div class="padding-style">
            <span class="motor-style"><img width="15" src="/assets/img/delivery_motor.png" alt="..." /></span>
            <span class="fa fa-scooter text-success pl-2 icon-color">
              {{ company?.deliveryFees | number:'1.2-2'}}</span>
          </div>
          <div class="padding-style">
            <span [ngClass]="current_lang == 'en' ? 'pr-1' : 'pl-1'" class="fa fa-lock icon-color"></span>
            <span class="icon-color">{{ company?.minOrderPrice | currency : settingService.getCurrencySymbol() }}</span>
          </div>

          <!-- <span class="bi bi-exclamation-circle"> Servicegebühr 0,89 €</span> -->
        </div>
        <div class="d-flex" id="top-nav">
          <div *ngIf="companyProducts.length == 0" class="item w-100">
            <ngx-skeleton-loader count="1" animation="progress" [theme]="{
                'border-radius': '5px',
                height: '40px',
                border: '1px solid white'
              }"></ngx-skeleton-loader>
          </div>
          <!-- <div id="mySwiper" class="swiper-container mt-2">
            <div class="swiper-wrapper">
              <div class="swiper-slide">شريحة 1</div>
              <div class="swiper-slide">شريحة 2</div>
              <div class="swiper-slide">شريحة 3</div>
              <div class="swiper-slide">شريحة 3</div>
              <div class="swiper-slide">شريحة 3</div>
              <div class="swiper-slide">شريحة 3</div>
              <div class="swiper-slide">شريحة 3</div>
              <div class="swiper-slide">شريحة 3</div>
              <div class="swiper-slide">شريحة 3</div>
            </div>
            <div class="swiper-pagination"></div>
          </div> -->
          <app-company-categories *ngIf="companyProducts.length != 0" (GetId)="getCompanyProductId($event)"
            [company_categories]="companyProducts"></app-company-categories>

        </div>
      </div>
    </div>
    <!-- <div *ngFor="let item of companyProducts; let i = index" class="shoppingBox" id="shoppingBox{{ item.id }}">
      <div class="item-title text-center pt-3">
        <h4>{{ item.name }}</h4>
      </div>
      <div *ngFor="let product of item.products; let j = index"
        class="item d-flex justify-content-between p-4 product-image product-{{ product.id }}">
        <div class="d-flex flex-column justify-content-between">
          <div *ngIf="product.isAvailable; else notAvailable">
            <span class="badge badge-success">{{'Available' | translate}}</span>
          </div>
          <ng-template #notAvailable>
            <span class="badge badge-danger">{{'Not Available' | translate}}</span>
          </ng-template>
          <h4 class="text-style-right">{{ product.name }}</h4>
          <h6 class="text-style-right">{{ product.description }}</h6>
          <h4 class="text-style-right">{{ product.price | currency : settingService.getCurrencySymbol() }}</h4>
        </div>
        <ngb-carousel class="ms-image-slider image-width" [showNavigationArrows]="false" [interval]="0"
          [showNavigationIndicators]="true" [animation]="true">
          <ng-template ngbSlide *ngFor="let image of product.productImages; let k = index">
            <img [src]="image.image" alt="{{image.name}}" class="img-fluid product-main-image" />
          </ng-template>
          <div class="carousel-indicators">
            <ng-container *ngFor="let image of product.productImages; let k = index">
              <li (click)="selectSlide(k)" [class.active]="k === currentSlideIndex"></li>
            </ng-container>
          </div>
        </ngb-carousel>
        <a (click)="addItemToBasket(product.id, item.id, product.name, product.description, product.price, product.isAvailable)"
          class="circle circlePos"><i class="fa fa-plus"></i></a>
      </div>
    </div> -->

    <div *ngFor="let item of companyProducts; let i = index" class="shoppingBox" id="shoppingBox{{ item.id }}">
      <div class="item-title text-center pt-3">
        <h4>{{ item.name }}</h4>
      </div>
      <div *ngFor="let product of item.products; let j = index"
        class="item d-flex justify-content-between p-4 product-image product-{{ product.id }}">
        <div class="d-flex flex-column justify-content-between">
          <div *ngIf="product.isAvailable; else notAvailable" class="text-style-right">
            <span class="badge badge-success">{{'Available' | translate}}</span>
          </div>
          <ng-template #notAvailable>
            <span class="badge badge-danger">{{'Not Available' | translate}}</span>
          </ng-template>
          <h4 class="text-style-right">{{ product.name }}</h4>
          <h6 class="text-style-right">{{ product.description }}</h6>
          <p class="text-style-right">{{ product.attributes}}</p>
          <h4 class="text-style-right">{{ product.price | currency : settingService.getCurrencySymbol() }}</h4>
        </div>
        <ngb-carousel class="ms-dotted-indicator-slider custom-carousel" [showNavigationArrows]="true"
          [showNavigationIndicators]="false">
          <ng-template ngbSlide *ngFor="let image of product.productImages">
            <img [src]="image.image" alt="{{image.name}}" class="img-fluid product-main-image carousel-image" />
          </ng-template>
        </ngb-carousel>

        <a (click)="addItemToBasket(product.id,product.branchId, item.id, product.name, product.description, product.price, product.isAvailable)"
          class="circle circlePos"><i class="fa fa-plus"></i></a>
      </div>
    </div>

  </div>

  <div id="sideBar" class="col-lg-3 p-3 vh-lg-100">
    <app-basket [minOrder]="company?.minOrderPrice" [deliveryCost]="cost" [orders]="orders"></app-basket>
  </div>
</div>
<ng-template #addItem let-modal>
  <div class="modal-header">
    <h3 class="modal-title has-icon ms-icon-round">
      <i class="flaticon-share bg-primary text-white"></i> Subscribe to our
      Newsletter
    </h3>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form method="post">
      <div class="ms-form-group has-icon">
        <label>Get our latest updates</label>
        <input type="text" placeholder="Email Address" class="form-control" name="news-letter" value="" />
        <i class="material-icons">email</i>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="modal.dismiss('Cross click')">
      Cancel
    </button>
    <button type="button" class="btn btn-primary shadow-none">Submit</button>
  </div>
</ng-template>

<!-- <div class="modal fade" id="addItemToBasket" data-backdrop="static" data-keyboard="false" tabindex="-1"
  aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title font-weight-bold" id="staticBackdropLabel">
          {{productName}}
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="product-title d-flex">
          <div class="d-flex flex-column">
            <p>{{description}}</p>
            <h3>{{ price | currency : settingService.getCurrencySymbol() }}</h3>
          </div>
          <div [ngClass]="current_lang == 'en' ? 'ml-auto' : 'mr-auto'">
            <figure>
              <img
                src="https://res.cloudinary.com/tkwy-prod-eu/image/upload/ar_1:1,c_thumb,h_120,w_120/f_auto/q_auto/dpr_1.0/v1686641613/static-takeaway-com/images/chains/de/subway/products/sub15cm_chicken_teriyaki"
                alt="..." />
            </figure>
          </div>
        </div>
        <div class="p-2 product-body">
          <div class="option mb-2" *ngFor="let detail of attributeDetails">
            <div class="d-flex justify-content-between">
              <div class="d-flex flex-column">
                <p class="font-weight-bold mb-0">{{detail.name}}</p>
                <p class="d-flex">
                  <span class="text-success">{{detail.minSelectedOptions ? ('Select at least' | translate) +
                    detail.minSelectedOptions + ' ,' : ''}}</span>
                  <span class="text-success">{{detail.maxSelectedOptions ? ('At most' | translate) +
                    detail.maxSelectedOptions : ''}}</span>
                </p>
              </div>
              <span *ngIf="detail.isRequired" class="badge-danger custom-badge">{{'Required' | translate}}</span>
              <span *ngIf="!detail.isRequired" class="badge-secondary custom-badge">{{'Optional2' | translate}}</span>
            </div>

            <ng-container *ngIf="detail.type == 0">
              <div class="form-checkbox"
                *ngFor="let option of detailStates[detail.id] ? detail.attributeOptions : (detail.attributeOptions | slice:0:3);let m = index">
                <label for="radio{{option.id}}" class="form-control-radio">
                  <input [checked]="detail.isRequired && detail.attributeOptions.length == 1" [value]="option.price"
                    (change)="changeFullPrice($event)" type="radio" id="radio{{option.id}}" name="radio{{detail.id}}" />
                  {{option.name}} ({{option.price | currency : settingService.getCurrencySymbol()}})
                </label>
              </div>
            </ng-container>
            <ng-container *ngIf="detail.type == 1">
              <div class="form-checkbox"
                *ngFor="let option of detailStates[detail.id] ? detail.attributeOptions : (detail.attributeOptions | slice:0:3);let m = index">
                <label class="form-control-style">
                  <input [checked]="detail.isRequired && detail.attributeOptions.length == 1" class="food-option"
                    [value]="option.price" (change)="changeFullPrice($event)" type="checkbox" id="checkbox{{option.id}}"
                    name="checkbox{{option.id}}" />
                  {{option.name}} ({{option.price | currency : settingService.getCurrencySymbol()}})
                </label>
              </div>
            </ng-container>
            <a class="show-btn" *ngIf="detail.attributeOptions.length > 3"
              (click)="detailStates[detail.id] = !detailStates[detail.id]">
              {{ detailStates[detail.id] ? ('Show less' | translate) : ('Show all' | translate) }}
              <span class="fa" [ngClass]="detailStates[detail.id] ? 'fa fa-angle-up' : 'fa fa-angle-down'"></span>
            </a>
            <hr />
          </div>
        </div>
      </div>
      <div class="modal-footer plus-minus-input d-flex">
        <div class="input-group-button">
          <button (click)="plusMinusBtn(1)" type="button" class="button hollow circle" data-quantity="minus"
            data-field="quantity">
            <i class="fa fa-minus" aria-hidden="true"></i>
          </button>
        </div>
        <input readonly class="input-group-field" min="1" type="number" name="quantity" value="1" />
        <div class="input-group-button">
          <button (click)="plusMinusBtn(2)" type="button" class="button hollow circle" data-quantity="plus"
            data-field="quantity">
            <i class="fa fa-plus" aria-hidden="true"></i>
          </button>
        </div>
        <div class="flex-grow-1">
          <button (click)="addOrder()" type="button" class="btn btn-confirm p-2">{{fullPrice | currency :
            settingService.getCurrencySymbol()}}</button>
        </div>
      </div>
    </div>
  </div>
</div> -->

<div class="modal fade" id="addItemToBasket" data-backdrop="static" data-keyboard="false" tabindex="-1"
  aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title font-weight-bold" id="staticBackdropLabel">
          {{productName}}
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="product-title d-flex">
          <div class="d-flex flex-column">
            <p>{{description}}</p>
            <h3>{{ price | currency : settingService.getCurrencySymbol() }}</h3>
          </div>
          <div [ngClass]="current_lang == 'en' ? 'ml-auto' : 'mr-auto'">
            <figure>
              <img
                src="https://res.cloudinary.com/tkwy-prod-eu/image/upload/ar_1:1,c_thumb,h_120,w_120/f_auto/q_auto/dpr_1.0/v1686641613/static-takeaway-com/images/chains/de/subway/products/sub15cm_chicken_teriyaki"
                alt="..." />
            </figure>
          </div>
        </div>
        <div class="p-2 product-body">
          <div class="option mb-2" *ngFor="let detail of attributeDetails">
            <div class="d-flex justify-content-between">
              <div class="d-flex flex-column">
                <p class="font-weight-bold mb-0">{{detail.name}}</p>
                <p class="d-flex">
                  <span class="text-success">{{detail.minSelectedOptions ? ('Select at least' | translate) +
                    detail.minSelectedOptions + ' ,' : ''}}</span>
                  <span class="text-success">{{detail.maxSelectedOptions ? ('At most' | translate) +
                    detail.maxSelectedOptions : ''}}</span>
                </p>
              </div>
              <span *ngIf="detail.isRequired" class="badge-danger custom-badge">{{'Required' | translate}}</span>
              <span *ngIf="!detail.isRequired" class="badge-secondary custom-badge">{{'Optional2' | translate}}</span>
            </div>

            <ng-container *ngIf="detail.type == 0">
              <div class="form-checkbox"
                *ngFor="let option of detailStates[detail.id] ? detail.attributeOptions : (detail.attributeOptions | slice:0:3);let m = index">
                <label for="radiofirst{{option.id}}" class="form-control-radio">
                  <input [checked]="detail.isRequired && detail.attributeOptions.length == 1" [value]="option.price"
                    (change)="changeFullPrice($event)" type="radio" id="radiofirst{{option.id}}"
                    name="radiofirst{{detail.id}}" />
                  {{option.name}} ({{option.price | currency : settingService.getCurrencySymbol()}})
                </label>
              </div>
            </ng-container>
            <ng-container *ngIf="detail.type == 1">
              <div class="d-flex align-items-center mb-2"
                *ngFor="let option of detailStates[detail.id] ? detail.attributeOptions : (detail.attributeOptions | slice:0:3); let m = index">
                <div class="d-flex align-items-center">
                  <img src="{{option.image}}" alt="..." class="option-image img-fluid" />
                  <div class="d-flex flex-column ml-2">
                    <p>{{option.name}}</p>
                    <p>{{option.price | currency : settingService.getCurrencySymbol() }}</p>
                  </div>
                </div>
                <div class="input-group-button ml-auto d-flex align-items-center">
                  <button (click)="updateOptionQuantity(detail.id, option.id, -1)" type="button" class="button circle"
                    [disabled]="optionQuantities[detail.id]?.[option.id] <= 0">
                    <i class="fa fa-minus" aria-hidden="true"></i>
                  </button>
                  <span class="quantity-display mx-2">{{ optionQuantities[detail.id]?.[option.id] || 0 }}</span>
                  <button (click)="updateOptionQuantity(detail.id, option.id, 1)" type="button" class="button circle"
                    [disabled]="getTotalQuantity(detail.id) >= detail.maxSelectedOptions">
                    <i class="fa fa-plus" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="detail.type == 2">
              <div *ngFor="let option of detail.attributeOptions">
                <div class="form-checkbox">
                  <label for="radiotree{{option.id}}" class="form-control-radio d-flex align-items-center">
                    <input [checked]="selectedOptions[detail.id] === option.id" [value]="option.price"
                      (change)="onOptionChange(detail, option)" type="radio" id="radiotree{{option.id}}"
                      name="radiotree{{detail.id}}" class="mr-2" />
                    {{option.name}} ({{option.price | currency : settingService.getCurrencySymbol()}})
                  </label>
                </div>

                <div *ngIf="selectedOptions[detail.id] === option.id">
                  <div *ngFor="let subAttribute of option.subAttributes" class="card p-2 mt-2 ml-4">
                    <p class="font-weight-bold mb-0">{{subAttribute.name}}</p>
                    <p class="text-success">
                      {{subAttribute.minSelectedOptions ? ('Select at least ' + subAttribute.minSelectedOptions + ', ')
                      : ''}}
                      {{subAttribute.maxSelectedOptions ? ('maximum ' + subAttribute.maxSelectedOptions) : ''}}
                    </p>
                    <ng-container *ngFor="let subOption of subAttribute.attributeOptions">
                      <div *ngIf="subAttribute.maxSelectedOptions == 1" class="form-checkbox ml-4">
                        <label for="subRadiotree{{subOption.id}}" class="form-control-radio d-flex align-items-center">
                          <img [src]="subOption.image" alt="{{subOption.name}}" class="mr-2"
                            style="width: 50px; height: 50px; object-fit: cover;" />
                          <input [checked]="subAttribute.isRequired && subAttribute.attributeOptions.length == 1"
                            [value]="subOption.price" (change)="changeFullPrice($event)" type="radio"
                            id="subRadiotree{{subOption.id}}" name="subRadiotree{{subAttribute.id}}" class="mr-2" />
                          {{subOption.name}} ({{subOption.price | currency : settingService.getCurrencySymbol()}})
                        </label>
                      </div>
                      <div *ngIf="subAttribute.maxSelectedOptions > 1" class="form-checkbox ml-4">
                        <label for="subCheckboxtree{{subOption.id}}"
                          class="form-control-checkbox d-flex align-items-center subCheckbox{{subOption.id}}">
                          <img [src]="subOption.image" alt="{{subOption.name}}" class="mr-2"
                            style="width: 50px; height: 50px; object-fit: cover;" />
                          <input [checked]="selectedSubOptions[subAttribute.id]?.has(subOption.id)"
                            [value]="subOption.price" (change)="changeFullPrice($event)" type="checkbox"
                            id="subCheckboxtree{{subOption.id}}" name="subCheckboxtree{{subAttribute.id}}" class="mr-2"
                            [attr.data-max-selected-options]="subAttribute.maxSelectedOptions" />
                          {{subOption.name}} ({{subOption.price | currency : settingService.getCurrencySymbol()}})
                        </label>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </ng-container>



            <a class="show-btn" *ngIf="detail.attributeOptions.length > 3"
              (click)="detailStates[detail.id] = !detailStates[detail.id]">
              {{ detailStates[detail.id] ? ('Show less' | translate) : ('Show all' | translate) }}
              <span class="fa" [ngClass]="detailStates[detail.id] ? 'fa fa-angle-up' : 'fa fa-angle-down'"></span>
            </a>
            <hr />
          </div>

          <!-- Addons Section -->
          <!-- Addons Section -->
          <div class="addons-section mt-4" *ngIf="addonProducts.length > 0">
            <h5 class="font-weight-bold addons-title">{{'Add-ons' | translate}}</h5>
            <div class="addon mb-3" *ngFor="let addon of addonProducts">
              <div class="addon-item d-flex align-items-center">
                <img src="{{addon.image}}" alt="{{addon.name}}" class="addon-image img-fluid rounded mr-3">
                <div class="d-flex flex-column">
                  <p class="addon-name mb-1">{{addon.name}}</p>
                  <p class="addon-price text-muted small">{{addon.price | currency :
                    settingService.getCurrencySymbol()}}</p>
                </div>
                <div class="addon-controls input-group-button ml-auto d-flex align-items-center">
                  <button (click)="updateAddonQuantity(addon.id, -1)" type="button"
                    class="btn btn-outline-secondary btn-sm rounded-circle" [disabled]="addonQuantities[addon.id] <= 0">
                    <i class="fa fa-minus" aria-hidden="true"></i>
                  </button>
                  <span class="quantity-display mx-2">{{ addonQuantities[addon.id] || 0 }}</span>
                  <button (click)="updateAddonQuantity(addon.id, 1)" type="button"
                    class="btn btn-outline-secondary btn-sm rounded-circle">
                    <i class="fa fa-plus" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- End Addons Section -->

          <!-- End Addons Section -->
        </div>
      </div>
      <div class="modal-footer plus-minus-input d-flex">
        <div class="input-group-button">
          <button (click)="plusMinusBtn(1)" type="button" class="button hollow circle" data-quantity="minus"
            data-field="quantity">
            <i class="fa fa-minus" aria-hidden="true"></i>
          </button>
        </div>
        <input readonly class="input-group-field" min="1" type="number" name="quantity" value="1" />
        <div class="input-group-button">
          <button (click)="plusMinusBtn(2)" type="button" class="button hollow circle" data-quantity="plus"
            data-field="quantity">
            <i class="fa fa-plus" aria-hidden="true"></i>
          </button>
        </div>
        <div class="flex-grow-1">
          <button (click)="addOrder()" type="button" class="btn btn-confirm p-2">{{fullPrice | currency :
            settingService.getCurrencySymbol()}}</button>
        </div>
      </div>
    </div>
  </div>
</div>



<ng-template #companyInfo let-modal>
  <div class="modal-header">
    <h3 class="modal-title has-icon ms-icon-round "><i class="flaticon-share bg-primary text-white"></i>
      {{'About this business' | translate}}
    </h3>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')"><span
        aria-hidden="true">&times;</span></button>
  </div>
  <div class="modal-body">
    <div class="ms-panel-body">
      <ul [(activeId)]="active" ngbNav #navtwo="ngbNav" class="nav nav-tabs tabs-bordered d-flex nav-justified mb-4">
        <li [ngbNavItem]="1">
          <a ngbNavLink>{{'Reviews' | translate}}</a>
          <ng-template ngbNavContent>
            <section class="restaurent-review smoothscroll" id="review">
              <div class="container">
                <div class="row">
                  <div class="col-12">
                    <div>
                      <h3 class="text-light-black title">{{'Reviews for' | translate}} {{company?.name}}</h3>
                    </div>
                    <div class="restaurent-rating mb-xl-20">
                      <div class="star">
                        <span><i class="fas fa-star main-color fs-16"
                            *ngFor="let item of getarr(company?.rate)"></i></span>
                        <span><i class="fa fa-star fs-16" *ngFor="let item of getarr(5 - company?.rate)"></i></span>
                        <!-- <span class="text-yellow fs-16">
                          <i class="fas fa-star"></i>
                        </span>
                        <span class="text-yellow fs-16">
                          <i class="fas fa-star"></i>
                        </span>
                        <span class="text-yellow fs-16">
                          <i class="fas fa-star"></i>
                        </span>
                        <span class="text-dark-white fs-16">
                          <i class="fas fa-star"></i>
                        </span>
                        <span class="text-dark-white fs-16">
                          <i class="fas fa-star"></i>
                        </span> -->
                      </div> <span class="fs-12 text-light-black">{{company?.reviewsCount}} {{'Ratings' |
                        translate}}</span>
                    </div>
                    <p class="text-light-black mb-xl-20">{{'Here\'s what people are saying' | translate}}:</p>
                    <!-- <ul>
                                  <li>
                                      <h6 class="text-light-black mb-1">73%</h6>
                                      <span class="text-light-black fs-12 fw-400">Food was good</span>
                                  </li>
                                  <li>
                                      <h6 class="text-light-black mb-1">85%</h6>
                                      <span class="text-light-black fs-12 fw-400">Delivery was on time</span>
                                  </li>
                                  <li>
                                      <h6 class="text-light-black mb-1">68%</h6>
                                      <span class="text-light-black fs-12 fw-400">Order was accurate</span>
                                  </li>
                              </ul> -->
                    <div class="u-line"></div>
                  </div>
                  <div class="col-md-12">
                    <div class="review-box" *ngFor="let review of companyReviews">
                      <div class="review-user">
                        <div class="review-user-img">
                          <img src="assets/img/blog-details/40x40/user-2.png" class="rounded-circle" alt="#">
                          <div class="reviewer-name">
                            <p class="text-light-black fw-600">{{review.customerName}}
                              <!-- <small class="text-light-white fw-500">New York,
                                (NY)</small> -->
                            </p> <i class="fas fa-trophy text-black"></i><span class="text-light-black">{{'Top Reviewer'
                              | translate}}</span>
                          </div>
                        </div>
                        <div class="review-date"> <span class="text-light-white">{{review.reviewDate |
                            date:'medium'}}</span>
                        </div>
                      </div>
                      <div class="ratings">
                        <span><i class="fas fa-star main-color fs-16"
                            *ngFor="let item of getarr(review.rate)"></i></span>
                        <span><i class="fa fa-star fs-16" *ngFor="let item of getarr(5 - review.rate)"></i></span>
                        <!-- <span class="text-yellow fs-16">
                          <i class="fas fa-star"></i>
                        </span>
                        <span class="text-yellow fs-16">
                          <i class="fas fa-star"></i>
                        </span>
                        <span class="text-yellow fs-16">
                          <i class="fas fa-star"></i>
                        </span>
                        <span class="text-yellow fs-16">
                          <i class="fas fa-star"></i>
                        </span>
                        <span class="text-yellow fs-16">
                          <i class="fas fa-star"></i>
                        </span> -->
                        <span class="ml-2 text-light-white">{{ getRelativeDate(review.reviewDate).daysAgo }} {{
                          getRelativeDate(review.reviewDate).display | translate }}</span>
                      </div>
                      <p class="text-light-black">{{review.content}}</p>
                      <!-- <span
                        class="text-light-white fs-12 food-order">Kathy ordered:</span>
                      <ul class="food">
                        <li>
                          <button class="add-pro bg-gradient-red">Coffee <span class="close">+</span>
                          </button>
                        </li>
                        <li>
                          <button class="add-pro bg-dark">Pizza <span class="close">+</span>
                          </button>
                        </li>
                        <li>
                          <button class="add-pro bg-gradient-green">Noodles <span class="close">+</span>
                          </button>
                        </li>
                        <li>
                          <button class="add-pro bg-gradient-orange">Burger <span class="close">+</span>
                          </button>
                        </li>
                      </ul> -->
                      <div class="u-line"></div>
                    </div>
                    <!-- <div class="review-box">
                      <div class="review-user">
                        <div class="review-user-img">
                          <img src="assets/img/blog-details/40x40/user-3.png" class="rounded-circle" alt="#">
                          <div class="reviewer-name">
                            <p class="text-light-black fw-600">Sarra <small class="text-light-white fw-500">New York,
                                (NY)</small>
                            </p> <i class="fas fa-trophy text-black"></i><span class="text-light-black">Top
                              Reviewer</span>
                          </div>
                        </div>
                        <div class="review-date"> <span class="text-light-white">Sep 20, 2022</span>
                        </div>
                      </div>
                      <div class="ratings"> <span class="text-yellow fs-16">
                          <i class="fas fa-star"></i>
                        </span>
                        <span class="text-yellow fs-16">
                          <i class="fas fa-star"></i>
                        </span>
                        <span class="text-yellow fs-16">
                          <i class="fas fa-star"></i>
                        </span>
                        <span class="text-yellow fs-16">
                          <i class="fas fa-star"></i>
                        </span>
                        <span class="text-yellow fs-16">
                          <i class="fas fa-star"></i>
                        </span>
                        <span class="ml-2 text-light-white">2 days ago</span>
                      </div>
                      <p class="text-light-black">Delivery was fast and friendly. Food was not great especially the
                        salad. Will not be ordering from again. Too many options to settle for this place.</p> <span
                        class="text-light-white fs-12 food-order">Kathy ordered:</span>
                      <ul class="food">
                        <li>
                          <button class="add-pro bg-gradient-red">Coffee <span class="close">+</span>
                          </button>
                        </li>
                        <li>
                          <button class="add-pro bg-dark">Pizza <span class="close">+</span>
                          </button>
                        </li>
                        <li>
                          <button class="add-pro bg-gradient-green">Noodles <span class="close">+</span>
                          </button>
                        </li>
                        <li>
                          <button class="add-pro bg-gradient-orange">Burger <span class="close">+</span>
                          </button>
                        </li>
                      </ul>
                    </div>
                    <div class="review-box">
                      <div class="review-user">
                        <div class="review-user-img">
                          <img src="assets/img/blog-details/40x40/user-4.png" class="rounded-circle" alt="#">
                          <div class="reviewer-name">
                            <p class="text-light-black fw-600">Sarra <small class="text-light-white fw-500">New York,
                                (NY)</small>
                            </p> <i class="fas fa-trophy text-black"></i><span class="text-light-black">Top
                              Reviewer</span>
                          </div>
                        </div>
                        <div class="review-date"> <span class="text-light-white">Sep 20, 2022</span>
                        </div>
                      </div>
                      <div class="ratings"> <span class="text-yellow fs-16">
                          <i class="fas fa-star"></i>
                        </span>
                        <span class="text-yellow fs-16">
                          <i class="fas fa-star"></i>
                        </span>
                        <span class="text-yellow fs-16">
                          <i class="fas fa-star"></i>
                        </span>
                        <span class="text-yellow fs-16">
                          <i class="fas fa-star"></i>
                        </span>
                        <span class="text-yellow fs-16">
                          <i class="fas fa-star"></i>
                        </span>
                        <span class="ml-2 text-light-white">2 days ago </span>
                      </div>
                      <p class="text-light-black">Delivery was fast and friendly. Food was not great especially the
                        salad. Will not be ordering from again. Too many options to settle for this place.</p> <span
                        class="text-light-white fs-12 food-order">Kathy ordered:</span>
                      <ul class="food">
                        <li>
                          <button class="add-pro bg-gradient-red">Coffee <span class="close">+</span>
                          </button>
                        </li>
                        <li>
                          <button class="add-pro bg-dark">Pizza <span class="close">+</span>
                          </button>
                        </li>
                        <li>
                          <button class="add-pro bg-gradient-green">Noodles <span class="close">+</span>
                          </button>
                        </li>
                        <li>
                          <button class="add-pro bg-gradient-orange">Burger <span class="close">+</span>
                          </button>
                        </li>
                      </ul>
                    </div> -->
                  </div>
                  <div class="col-12">
                    <div class="review-img">
                      <img src="assets/img/review-footer.png" class="img-fluid" alt="#">
                      <div class="review-text">
                        <h2 class="text-light-white mb-2 fw-600">{{'Be one of the first to review' | translate}}</h2>
                        <p class="text-light-white">{{'Order now and write a review to give others a good impression' |
                          translate}}.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </ng-template>
        </li>
        <li [ngbNavItem]="2">
          <a ngbNavLink>{{'Info' | translate}} </a>
          <ng-template ngbNavContent>
            <section class="restaurent-about smoothscroll mb-2" id="about">
              <div class="container">
                <div class="row">
                  <div class="col-md-12">
                    <h3 class="text-light-black fw-700 title">{{company?.name}} {{'Menu' | translate}} {{'Info' |
                      translate}}</h3>
                  </div>
                  <div class="col-md-12">
                    <agm-map [latitude]="location[0]" [longitude]="location[1]" style="height: 400px;" [zoom]="15">
                      <agm-marker [latitude]="location[0]" [longitude]="location[1]"></agm-marker>
                    </agm-map>
                  </div>
                  <div class="col-md-12">
                    <!-- <p class="no-margin">{{company?.description}}</p> -->
                    <p class="text-light-green no-margin">{{getConcatenatedNames()}}</p>
                    <p class="text-light-white no-margin">{{company?.description}}</p>
                    <!-- <span class="text-success fs-16">$</span> -->
                    <!-- <span class="text-success fs-16">$</span>
                    <span class="text-success fs-16">$</span>
                    <span class="text-dark-white fs-16">$</span>
                    <span class="text-dark-white fs-16">$</span> -->
                    <!-- <ul class="about-restaurent">
                      <li> <i class="fas fa-map-marker-alt"></i>
                        <span>
                          <a routerLink="/restaurant" class="text-light-white">
                            314 79th St<br>
                            Rite Aid, Brooklyn, NY, 11209
                          </a>
                        </span>
                      </li>
                      <li> <i class="fas fa-phone-alt"></i>
                        <span><a href="tel:" class="text-light-white">(347) 123456789</a></span>
                      </li>
                      <li> <i class="far fa-envelope"></i>
                        <span><a href="mailto:" class="text-light-white">demo@domain.com</a></span>
                      </li>
                    </ul> -->
                    <!-- <ul class="social-media pt-2">
                      <li> <a routerLink="/restaurant"><i class="fab fa-facebook-f"></i></a>
                      </li>
                      <li> <a routerLink="/restaurant"><i class="fab fa-twitter"></i></a>
                      </li>
                      <li> <a routerLink="/restaurant"><i class="fab fa-instagram"></i></a>
                      </li>
                      <li> <a routerLink="/restaurant"><i class="fab fa-pinterest-p"></i></a>
                      </li>
                      <li> <a routerLink="/restaurant"><i class="fab fa-youtube"></i></a>
                      </li>
                    </ul> -->
                  </div>
                  <div class="col-md-12">
                    <div class="restaurent-schdule">
                      <div class="card">
                        <div class="card-header text-light-white fw-700 fs-16 mt-2"><i class="fas fa-coins"></i>
                          {{'Fees' | translate}}</div>
                        <div class="card-body">
                          <div class="schedule-box">
                            <div class="day text-light-black">{{'Minimum amount' | translate}}</div>
                            <div class="time text-light-black">{{company?.minOrderPrice | currency :
                              settingService.getCurrencySymbol()}}</div>
                          </div>
                          <div class="schedule-box">
                            <div class="day text-light-black">{{'Delivery Costs' | translate}}</div>
                            <div class="time text-light-black">{{company?.deliveryFees | currency :
                              settingService.getCurrencySymbol()}}: {{company?.workHours}}</div>
                          </div>
                          <!-- <div class="schedule-box">
                              <div class="day text-light-black">{{'tarik'}}</div>
                              <div class="time text-light-black">{{'Delivery' | translate}}: {{company?.workHours}}</div>
                            </div> -->
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="restaurent-schdule">
                      <div class="card">
                        <div class="card-header text-light-white fw-700 fs-16 mt-2"><i class="fas fa-calendar-alt"></i>
                          {{'Work Days' | translate}}</div>
                        <div class="card-body">
                          <ng-container *ngFor="let day of days;let i = index">
                            <div *ngIf="isWorkDay(day.id)" class="schedule-box"
                              [ngClass]="i == day_count - 1 ? 'pb-3' : ''">
                              <div class="day text-light-black">{{day.name}}</div>
                              <div class="time text-light-black">{{'Delivery' | translate}}: {{company?.workHours}}
                              </div>
                            </div>
                          </ng-container>

                          <!-- <div class="collapse" id="schdule">
                            <div class="schedule-box">
                              <div class="day text-light-black">Tuesday</div>
                              <div class="time text-light-black">Delivery: 7:00am - 10:00pm</div>
                            </div>
                            <div class="schedule-box">
                              <div class="day text-light-black">Wednesday</div>
                              <div class="time text-light-black">Delivery: 7:00am - 10:00pm</div>
                            </div>
                            <div class="schedule-box">
                              <div class="day text-light-black">Thursday</div>
                              <div class="time text-light-black">Delivery: 7:00am - 10:00pm</div>
                            </div>
                            <div class="schedule-box">
                              <div class="day text-light-black">Friday</div>
                              <div class="time text-light-black">Delivery: 7:00am - 10:00pm</div>
                            </div>
                            <div class="schedule-box">
                              <div class="day text-light-black">Saturday</div>
                              <div class="time text-light-black">Delivery: 7:00am - 10:00pm</div>
                            </div>
                            <div class="schedule-box">
                              <div class="day text-light-black">Sunday</div>
                              <div class="time text-light-black">Delivery: 7:00am - 10:00pm</div>
                            </div>
                          </div>
                          <div class="card-footer mt-auto"> <a class="fw-500 collapsed" data-toggle="collapse" href="#schdule">{{'See the full schedule' | translate}}</a>
                          </div> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </ng-template>
        </li>
        <!-- <li [ngbNavItem]="3">
          <a ngbNavLink>Settings </a>
          <ng-template ngbNavContent>
            <p> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam urna nunc, congue nec
              laoreet sed, maximus non massa. Fusce vestibulum vel risus vitae tincidunt. </p>
          </ng-template>
        </li> -->
      </ul>
      <div [ngbNavOutlet]="navtwo"></div>
    </div>
  </div>
  <!-- <div class="modal-footer">
      <button type="button" class="btn btn-light" (click)="modal.dismiss('Cross click')">Cancel</button>
      <button type="button" class="btn btn-primary shadow-none">Submit</button>
  </div> -->
</ng-template>